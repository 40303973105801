import React, { Component } from 'react'
import { Card , Container, Segment } from 'semantic-ui-react'
import { ClusterCard } from './clusterCard'
import PropTypes from 'prop-types'
import Platform from '../libs/platform'
import _ from 'lodash'

export class ClusterListView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            cspId: this.props.cspId,
            clusters: {},
            loaded: false,
            idToIndex: {},
        }
     }

    platform = new Platform(this)

    // process clusters events from the websocket
    messageProcessing(event) {
        if (event.objectType != "OBJECT_TYPE_CLUSTER") {
            return
        }
        const clusterIndex = this.state.idToIndex[event.objectId]
        switch (event.event) {
            case "OBJECT_ACTION_UPDATE":
                console.log("update cluster info %s", event.objectId)
                this.retrieveCluster(event.objectId, clusterIndex)
                break
            default:
                console.log("unhandled event: %o", event)
        }
    }

    retrieveCluster(id, index) {

        console.log("will refresh cluster %s at index %d", id, index)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const clusterURL = '/api/v1/clusters/read'

        fetch(clusterURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                let newClusters = this.state.clusters
                newClusters.list[index] = result
                this.setState(newClusters)
                console.log("state updated with new cluster props: %o", result)
            },
            (error) => {
            console.log("cannot retrieve cluster: %o", error);
            }
        )
    }

    retrieveClusters() {
        const { cspId } = this.state
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"credential_id": cspId})
        };

        fetch("/api/v1/clusters/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {

                // order received list from last scan timestamp
                result.list = _.orderBy(result.list, (item) => {
                    if (item.last_scan != null) return item.last_scan.timestamp;
                    else return 0
                }, 'desc')

                let idToIndex = {}
                result.list.forEach((cluster, index) => {
                    idToIndex[cluster.id] = index
                });
                this.setState({clusters : result, loaded: true, idToIndex: idToIndex})
            },
            (error) => {
                console.log("cannot retrieve clusters: %o", error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        //this.platform.setupPlatformContext(this)
        this.platform.subscribe(this)
        console.log("retrieve clusters...")
        this.retrieveClusters()
    }

    render() {  

        const { cspId, clusters, loaded } = this.state
        console.log("render clusters: %o", clusters)


        if (!loaded) {
            console.log("still not loaded")
        } else {
            return (
                <Container fluid>
                    <Segment basic textAlign='left'>
                    <Card.Group >
                    {clusters.list.map(cluster => (
                        <ClusterCard key={cluster.id} cluster={cluster} cspId={cspId}></ClusterCard>
                    ))}
                    </Card.Group>
                    </Segment>
                </Container>
            ) 
        }    
    }  
}
export default ClusterListView  
ClusterListView.propTypes = {  
    cspId: PropTypes.string.isRequired, 
}  