import React, { Component } from 'react'  
import {  Segment, CardGroup, Card, Icon, Button, Divider } from 'semantic-ui-react'
import OrganizationNewModal from '../components/organizationNewModal';


export class OrganizationTable extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            organizations: {},
            invitations: {},
            deleteConfirm: false,
            orgsLoaded: false,
            invitationsLoaded: false,
        }
    }

    retrieveOrganizations = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/v1/organizations/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({organizations : result.org_items, orgsLoaded: true})
                console.log('received orgs: %o', result)
            },
            (error) => {
                console.log("cannot retrieve orgs: %o", error);
            }
        )
    }

    retrieveMyInvitations() {

        console.log("retrieve invitations from state: %o", this.state)

        const { userContext } = this.state
        console.log("retrieve invitations with context: %o", userContext)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({as_user: true}),
        };

        fetch("/api/v1/organizations/invitation/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({invitations : result.items, invitationsLoaded: true})
                console.log("received invitations: %o", result)
            },
            (error) => {
                console.log("cannot retrieve invitations: %o", error);
            }
        )
    }

    newOrgAdded() {
        console.log("found a new org added - refresh main page organizations")
        this.retrieveOrganizations()
    }

    impersonate(id) {

        console.log("impersonate org %s", id)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"user_id": id})
        };

        fetch("/api/v1/user/impersonate", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({orgs : result, loaded: true})
                console.log("impersonate: %o", result)
                window.location.href='/ui/csp'
            },
            (error) => {
                console.log("cannot retrieve organizations: %o", error);
            }
        )
    }

    acceptInvitation(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/organizations/invitation/approve", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("accepted invitation: %o", result)
                this.retrieveMyInvitations()
                this.retrieveOrganizations()

            },
            (error) => {
                console.log("cannot accept invitation: %o", error);
            }
        )
    }

    cancelInvitation(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/organizations/invitation/delete", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("deleted invitation: %o", result)
                this.retrieveMyInvitations()
                this.retrieveOrganizations()

            },
            (error) => {
                console.log("cannot accept invitation: %o", error);
            }
        )
    }

    componentDidUpdate() {
        console.log('update org card props')
    }

    // This view require to be authenticated 
    componentDidMount() {
        console.log("retrieve my orgs and invitations...")
        this.retrieveMyInvitations()
        this.retrieveOrganizations()
    }

    render () {

        const { organizations, invitations, orgsLoaded, invitationsLoaded } = this.state
        console.log("render orgs: %o and invitations: %o", organizations, invitations)

        if (!orgsLoaded || !invitationsLoaded) {
            console.log("loading...")
        } else {
            return (
                <Segment basic>
                <CardGroup>
                {organizations.map(org => (
                    <Card key={org.id} onClick={() => this.impersonate(org.org_id)}>
                        <Card.Content>
                            <Card.Header>
                            <Icon name="users" color='red'/> {org.org_name}
                            </Card.Header>
                            <Card.Meta>
                                Organization
                            </Card.Meta>
                        </Card.Content>
                    </Card>
                ))}
                {invitations.map(invitation => (
                    <Card key={invitation.id} color='red'>
                        <Card.Content>
                            <Card.Header>
                            Invitation
                            </Card.Header>
                            <Card.Description>
                            You have been invited by <b>{invitation.sent_by}</b> to join the organization <b>{invitation.organization_name}</b> as {invitation.type}
                            </Card.Description>
                        </Card.Content>
                        <Card.Content textAlign='right' extra>
                            <Card.Description>
                            <Button
                                basic
                                onClick={() => this.acceptInvitation(invitation.id)}
                                >
                                <Icon name="check" color='green'/> Accept
                            </Button>
                            <Button
                                basic
                                onClick={() => this.cancelInvitation(invitation.id)}
                                >
                                <Icon name="times" color='red'/> Refuse
                            </Button>
                            </Card.Description>
                        </Card.Content>
                    </Card>
                ))}
                </CardGroup>
                <Divider />
                <Card key='new' color='green' >
                        <Card.Content>
                            <Card.Header>
                                New organization
                            </Card.Header>
                            <Card.Description>
                            Create a new organization and unlock endless security possibilities. Let&lsquo;s get started!
                            </Card.Description>
                        </Card.Content>
                        <Card.Content textAlign='right' extra>
                            <Card.Description>
                            <OrganizationNewModal callerObj={this}/>
                            </Card.Description>
                        </Card.Content>
                    </Card>
            </Segment>
            )
        }
    }

}
export default OrganizationTable  
