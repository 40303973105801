import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Menu , Dropdown, Image } from 'semantic-ui-react'  
import Platform from '../libs/platform'
import PropTypes from 'prop-types';  


export class MenuBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            activeItem: this.props.activeItem,
            loaded: false,
            userContext: this.props.userContext
        }
        console.log("create menuBar with active item set to %s", this.state.activeItem);
     }
    
    platform = new Platform()


    // user logout is handled as a specific function
    userLogout() {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/v1/auth/logout", requestOptions)
        .then(res => res.json())
        .then(
          () => {
            window.location.href="/";
          },
          (error) => {
            console.log(error);
          }
        )
    }

    render() {  

        const { userContext } = this.state

        console.log("render menu bar with props: %o", this.props)

        return (  
            <Menu borderless fluid attached={'top'} size='mini'>

                <Menu.Item>
                    <Image alt='aphid.cloud' src='/logo.jpg' rounded size='mini'></Image> 
                </Menu.Item>

                {/*
                <Menu.Item 
                    name='workload' 
                    active={'workloads' === this.state.activeItem}
                    as={Link} 
                    to="/ui/workloads" >
                    Workloads
                </Menu.Item>
                 
                <Menu.Item 
                    name='inventory' 
                    active={'csp' === this.state.activeItem}
                    as={Link} 
                    to="/ui/csp" >
                    Inventory
                </Menu.Item>
                */}
               
                <Menu.Menu position='right'>
                    <Dropdown item icon={'user'}>
                        <Dropdown.Menu direction='left'>
                            <Dropdown.Header>
                            {userContext.user.username}
                            </Dropdown.Header>
                            <Dropdown.Divider />
                            <Dropdown.Item 
                                as={Link} 
                                to="/ui/user">

                                Profile
                            </Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item 
                                onClick={this.userLogout} >
                                Logout
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Menu.Menu>
            </Menu>
        )  
    }  
}
export default MenuBar

MenuBar.propTypes = {  
    userContext: PropTypes.object.isRequired,
    activeItem: PropTypes.string.isRequired,
}  