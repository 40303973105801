import React, { Component } from 'react'  
import { Breadcrumb, Icon, Grid } from 'semantic-ui-react'  
import PropTypes from 'prop-types'

export class CspNavBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            obj: this.props.obj,
            objType: this.props.objType,
            userContext: this.props.userContext,
            sections: [],
            loaded: false,
        }

        console.log("display breacrumb with obj: %o", this.state.obj)
    }
    

    handleClick(objType, id) {
        console.log("click on %s %s", objType, id)

        let targetURL = ''

        switch (objType) {
            case "home":
                targetURL = "/ui/"
                console.log("redirect to url " + targetURL)
                window.location.href = targetURL
                break
            case "account":
                targetURL = "/ui/csp/" + id
                console.log("redirect to url " + targetURL)
                window.location.href = targetURL
                break
            case "cluster":
                targetURL = "/ui/csp/clusters/" + id
                console.log("redirect to url " + targetURL)
                window.location.href = targetURL
                break
            case "repository":
                targetURL = "/ui/csp/repositories/" + id
                console.log("redirect to url " + targetURL)
                window.location.href = targetURL
                break
        }
    }

    handleIconClick(objType, id) {
        console.log("click on %s %s", objType, id)

        let targetURL = ''

        switch (objType) {
            case "orgSettings":
                targetURL = "/ui/organization/settings"
                console.log("redirect to url " + targetURL)
                window.location.href = targetURL
                break
        }
    }

    addPath() {

        const { obj, objType, userContext} = this.state

        console.log("display type %s with object %o", objType, obj)

        switch (objType) {
            case "org":
                return (
                <React.Fragment>
                    <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                </React.Fragment>
                )
            case "orgSettings":
                    return (
                    <React.Fragment>
                        <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><Icon name='setting' color='red' />Settings</Breadcrumb.Section>
                    </React.Fragment>
                    )
            case "account":
                return (
                <React.Fragment>
                    <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                    <Breadcrumb.Divider />
                    <Breadcrumb.Section active><Icon name='key' color='red' />{obj.name}</Breadcrumb.Section>
                </React.Fragment>
                )
            case "cluster":
                return (
                    <React.Fragment>
                        <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", obj.parent.account_id)}><Icon name='key' color='red' />{obj.parent.account_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><Icon name='cubes' color='red' /> {obj.meta.name}</Breadcrumb.Section>
                    </React.Fragment>
                )
            case "instance":
                return (
                    <React.Fragment>
                        <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", obj.parent.account_id)}><Icon name='key' color='red' />{obj.parent.account_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><Icon name='server' color='red' /> {obj.name}</Breadcrumb.Section>
                    </React.Fragment>
                )
            case "pod":
                return (
                    <React.Fragment>
                        <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", obj.parent.account_id)}><Icon name='key' color='red' />{obj.parent.account_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("cluster", obj.parent.cluster_id)}><Icon name='cubes' color='red' /> {obj.parent.cluster_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><Icon name='cube' color='red' /> {obj.name}</Breadcrumb.Section>
                    </React.Fragment>
                )
            case "image":
                return (
                    <React.Fragment>
                        <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", obj.parent.account_id)}><Icon name='key' color='red' />{obj.parent.account_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("repository", obj.parent.repo_id)}><Icon name='cubes' color='red' /> {obj.parent.repo_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><Icon name='cube' color='red' /> {obj.url}</Breadcrumb.Section>
                    </React.Fragment>
                )
            case "tag":
                return (
                    <React.Fragment>
                        <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", obj.parent.account_id)}><Icon name='key' color='red' />{obj.parent.account_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("repository", obj.parent.container_repo_id)}><Icon name='docker' color='red' /> {obj.registry}/{obj.parent.container_repo_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><Icon name='tag' color='red' /> {obj.version}</Breadcrumb.Section>
                    </React.Fragment>
                )
            case "tags":
                return (
                    <React.Fragment>
                        <Breadcrumb.Section link onClick={() => this.handleClick("home", "")}><Icon name='home' color='red' />Home</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", "")}><Icon name='users' color='red' />{userContext.impersonate.username}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section link onClick={() => this.handleClick("account", obj.parent.account_id)}><Icon name='key' color='red' />{obj.parent.account_name}</Breadcrumb.Section>
                        <Breadcrumb.Divider />
                        <Breadcrumb.Section active><Icon name='docker' color='red' />{obj.registry}/{obj.repository}</Breadcrumb.Section>
                    </React.Fragment>
                )
            default:
                return (
                    <Breadcrumb.Section><Icon name='home' color='red' />Home</Breadcrumb.Section>
                )
        }

    }

    addIcon() {

        const { objType } = this.state

        switch (objType) {
            case "org":
                return (<Icon link name='setting' color='red' onClick={() => this.handleIconClick("orgSettings", "")} />)
            default: 
                return
        }

    }

    render() {
        return (  
            <Grid columns={2}>
                <Grid.Column>
                    <Breadcrumb size='small'>
                        {this.addPath()}
                    </Breadcrumb>
                </Grid.Column>
                <Grid.Column textAlign='right'>
                    {this.addIcon()}
                </Grid.Column>
            </Grid>
            
        )  
    }  
}
export default CspNavBar
CspNavBar.propTypes = {  
    objType: PropTypes.string.isRequired,
    obj: PropTypes.object.isRequired,
    userContext: PropTypes.object.isRequired,
}  