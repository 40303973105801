import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card , Label, Icon, Grid } from 'semantic-ui-react'
import PropTypes from 'prop-types';  


export class ContainerImageRepoCard extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            repo: props.repo,
        }
    }

    componentDidUpdate() {
        console.log('updated instance card props')
    }

    repoIsOwner(repo) {
        if (repo.is_managed) {
            return (
                <Label basic circular color='red'>In subscription</Label>
            )
        } else {
            return (
                <Label basic circular color='grey'>third party</Label>
            )
        }
    }

    render () {
        const repo = this.props.repo
        console.log("render repo: %o", repo)
        const repoTagURL = '/ui/csp/repositories/' + repo.id
        return (
            <Card key={repo.id} as={Link} to={repoTagURL}>
                <Card.Content>
                    <Card.Header>
                    <Grid columns={2} className='break-line'>
                        <Grid.Column width={13}>
                        {repo.registry}
                        </Grid.Column>
                        <Grid.Column width={3}>
                        <Icon name="docker" color='red' size='large' />
                        </Grid.Column>
                    </Grid>
                    </Card.Header>
                    <Card.Meta>{repo.name}</Card.Meta>
                </Card.Content>
                <Card.Content>
                <Card.Description>
                <Label 
                    basic
                    to={repoTagURL}
                    circular>Tags: {repo.num_tags}</Label>
                {this.repoIsOwner(repo)}
                </Card.Description>
                </Card.Content>
            </Card>
        )
    }

}
export default ContainerImageRepoCard  

ContainerImageRepoCard.propTypes = {  
    repo: PropTypes.object.isRequired, 
}  