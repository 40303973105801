import React, { Component } from 'react'  
import { Segment,Card, Grid, GridColumn, List, Divider} from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import {CspNavBar } from '../components/breadcrumb'
import Platform from '../libs/platform'
import InstanceVulns from '../components/instanceVulns'
import PropTypes from 'prop-types';  

export class InstanceView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            properties: props,
            loaded: false,
            instanceId: this.props.instanceId,
        }
     }

    platform = new Platform()
    
    retrieveInstance(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/instance/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            this.setState({instance : result, loaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        const { instanceId } = this.state
        this.platform.setupPlatformContext(this)
        console.log("retrieve instance...")
        this.retrieveInstance(instanceId)
    }

    renderInstance() {

        const { loaded, instance } = this.state

        if (!loaded) return

        return (
                <Grid columns={2}>
                    <GridColumn width={10}>
                    <Card key={instance.id} fluid>
                        <Card.Content>
                        <Card.Header>
                        {instance.name}
                        </Card.Header>
                        <Card.Meta>
                        {instance.vendor}
                        </Card.Meta>
                        <Card.Description>
                        OS: {instance.os}<br/>
                        Type: {instance.type}
                        </Card.Description>
                        </Card.Content>
                    </Card>
                    <InstanceVulns instanceId={instance.id} />
                    </GridColumn>
                    <GridColumn width={6}>
                    <Card key={instance.id} fluid>
                        <Card.Content>
                        <Card.Header>
                        Network
                        </Card.Header>
                        <Card.Meta>
                        </Card.Meta>
                        <Card.Description textAlign='left'>
                        <List divided relaxed>
                        {instance.network_interfaces.map(iface =>(
                            <List.Item key={iface.name}>
                                <List.Content>
                                    <List.Header>
                                    {iface.name}
                                    </List.Header>
                                    <List>
                                        {iface.ips.map(ip=>(
                                            <List.Item key={ip.value}>
                                                {ip.value}
                                            </List.Item>
                                        ))}      
                                    </List>
                                </List.Content>
                            </List.Item>
                        ))}
                        </List>

                        </Card.Description>
                        </Card.Content>
                    </Card>

                        
                    </GridColumn>
                </Grid>
        )
    }

    render() {  

        const { instance, loaded, userCtx, userCtxLoaded } = this.state

        if (userCtxLoaded && loaded) {

            console.log("instance: %o", instance)

            return (
                <div className='Main'>
                <MenuBar userContext={userCtx} activeItem="csp"></MenuBar>
                <Segment basic loading={!loaded} textAlign='left'>
                <CspNavBar userContext={userCtx} obj={instance} objType="instance"/>
                <Divider />
                    {this.renderInstance()}
                </Segment>
                </div>
            ) 
        }            
    }  
}
export default InstanceView

InstanceView.propTypes = {  
    instanceId: PropTypes.string.isRequired,
}  