import React, { Component } from 'react'  
import { Card, Segment} from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import Platform from '../libs/platform'
import PropTypes from 'prop-types';  
import { Buffer } from 'buffer'
import { Vulns } from '../components/Vulns'
import CspNavBar from '../components/breadcrumb';


export class ImageView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            properties: props,
            loaded: false,
            reportLoaded: false,
            tagId: this.props.tagId,
        }
     }

    platform = new Platform()
    
    retrieve(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/containers/tag/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log("container tag: %o", result)
            this.setState({tag : result, loaded: true})

            // if current image
            this.retrieveImage(result.current_image.id)

            },
            (error) => {
            console.log(error);
            }
        )
    }

    retrieveImage(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/images/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {

            var decodedReport = Buffer.from(result.report, 'base64')
            var reportData = decodedReport.toString('utf8')
            let report = JSON.parse(reportData)

            console.log("container tag report: %o", report)
            this.setState({report: report, reportLoaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        const { tagId } = this.state
        this.platform.setupPlatformContext(this)
        console.log("retrieve tag...")
        this.retrieve(tagId)
    }

    renderImage() {

        const { loaded, tag } = this.state

        if (!loaded) return

        console.log("display tag: %o", tag)

        return (
            <div>
            <Card key={tag.id} fluid>
                <Card.Content>
                <Card.Header>
                {tag.registry}/{tag.repository}:{tag.version}
                </Card.Header>
                <Card.Meta>
                {tag.current_image.digest}
                </Card.Meta>
                <Card.Description>
                </Card.Description>
                </Card.Content>
            </Card>
            </div>
        )
    }

    render() {  

        const { loaded, userCtx, userCtxLoaded, reportLoaded, tag, report } = this.state

        if (loaded && userCtxLoaded && reportLoaded) {


            return (
            <div className='Main'>
                <MenuBar activeItem="csp" userContext={userCtx}></MenuBar>
                <Segment basic textAlign='left'>
                <CspNavBar userContext={userCtx} obj={tag} objType="tag" />
                        <Segment basic>
                        {this.renderImage()}
                        </Segment>
                        <Segment basic>
                            <Vulns report={report}/>
                        </Segment>
                </Segment>
            </div>
            ) 
        }            
    }  
}
export default ImageView

ImageView.propTypes = {  
    tagId: PropTypes.string.isRequired, 
}  