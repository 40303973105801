import { w3cwebsocket as W3CWebSocket } from "websocket"


export class Platform {
    subscribe(obj) {
        console.log("subscribe to websocket")
        const info = window.location
        const websocketURL = 'wss://' + info.host + '/ui/notifications'
        const client = new W3CWebSocket(websocketURL);
        client.onopen = () => {
            console.log('WebSocket Client Connected');
        };
        client.onmessage = (message) => {
            let event = JSON.parse(message.data);
            obj.messageProcessing(event)
        };
    }
   
    setupPlatformContext(obj) {
        console.log("retrieve platform status...")
        const statusRequestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/v1/status", statusRequestOptions)
            .then(response => {
                if (!response.ok) {
                    console.log("request failed with %o", response.status);
                    if (response.status == 401) {
                        console.log("user is not authenticated")
                        window.location.href="/";
                        return
                    }
                }
                return response.json()
            })
            .then(
            (result) => {
                if (!result.authenticated) {
                    console.log("user is not authenticated");
                    window.location.href="/";
                } else {
                    console.log("user is authenticated");
                }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                console.log(error);
            }
        )

        console.log("retrieve user context...")
        const contextRequestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };
    
        fetch("/api/v1/user/context", contextRequestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                obj.setState({userCtx : result, userCtxLoaded: true})
                console.log("received user context: %o", result)
            },
            (error) => {
                console.log("cannot retrieve user context: %o", error);
            }
        )
    }

} export default Platform  