import React, { Component } from 'react'  
import { Vulns } from '../components/Vulns'
import { Buffer } from 'buffer'
import PropTypes from 'prop-types';  

export class InstanceVulns extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            loaded: false,
            offset: 0,
            limit: 10,
            instanceId: props.instanceId,
        }
    }

    retrieveReport(id) {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        const retrieveURL = "/api/v1/instance/report/read"
        fetch(retrieveURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("got instance report: %o", result)
                if (result.report.length == 0) {
                    this.setState({loaded: false})
                } else {
                    this.setState({vulnReport : result.report, loaded: true})
                }
            },
            (error) => {
            console.log(error);
            }
        )
    }

    openDetails(pkg) {
        console.log("open details for " + pkg)
    }

    vulnColor(severity) {
        switch (severity) {
            case 5:
                return 'red'
            case 4:
                return 'orange'
            case 3:
                return 'yellow'
            case 2:
                return 'green'
            default:
                return 'grey'
        }
    }

    cvssColor(score) {
        if (score > 9) {
            return 'red'
        }
        else if (score >= 7 && score < 9) {
            return 'orange'
        }
        else if (score >= 4 && score < 7) {
            return 'yellow'
        }
        else {
            return 'green'
        }
    }

    handlePaginationClick = (event, data) => {
        let newOffset = (data.activePage - 1) * 10
        console.log("will move to offset: " + newOffset)
        this.setState({offset: newOffset})
    }

    componentDidMount() {
        const { instanceId } = this.state
        console.log('retrieve report for instance ' + instanceId)
        this.retrieveReport(instanceId)
    }

    render () {
        const {loaded } = this.state

        if (!loaded) {
            console.log("still not loaded")
        } else {

            const { vulnReport} = this.state

            var decodedReport = Buffer.from(vulnReport, 'base64')
            var reportData = decodedReport.toString('utf8')
            var reportJSON = JSON.parse(reportData)

            return (
               <Vulns report={reportJSON} />
            )
        }
    }

}
export default InstanceVulns  

InstanceVulns.propTypes = {  
    instanceId: PropTypes.string.isRequired,
}  