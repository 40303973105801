import React, { Component } from 'react'  
import { Container,Card, Segment, List, Accordion, Icon, Header, Divider, Label, Grid} from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import Platform from '../libs/platform'
import PropTypes from 'prop-types';  
import PodContainerCard from '../components/PodContainerCard';
import CspNavBar from '../components/breadcrumb';


export class PodView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            activeIndex: -1,
            properties: props,
            loaded: false,
            podId: this.props.podId,
            sbomProcessing: {}
        }
     }

    platform = new Platform()

    // process clusters events from the websocket
    messageProcessing(event) {
        const { sbomProcessing, podId } = this.state
        console.log("event: %o", event)
        if (event.type == "task") {
            console.log("Task on sbom %s processing: %o",event.objectId, sbomProcessing[event.objectId])
            this.retrieve(podId)
        }
    }

    retrieve(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/pod/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {

            console.log("pod: %o", result)
            let sbomProcessing = this.state.sbomProcessing

            result.containers.forEach(container => {
                console.log("retrieved container %o", container)

                sbomProcessing[container.sbom_id] = container.is_sbom_processing
                console.log("set sbom %s with processing %s", container.sbom_id, container.is_sbom_processing.toString())
            })

            this.setState({pod : result, sbomProcessing: sbomProcessing, loaded: true})
            console.log("sbom processing: %o", this.state.sbomProcessing)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex })
    }    

    // This view require to be authenticated 
    componentDidMount() {
        const { podId } = this.state
        this.platform.setupPlatformContext(this)
        this.platform.subscribe(this)
        console.log("retrieve pod...")
        this.retrieve(podId)
    }


    renderPod() {
        const { loaded, pod } = this.state
        if (!loaded) return
        console.log("display pod: %o", pod)
        return (
            <div>
            <Card key={pod.id}>
                <Card.Content textAlign='left'>
                <Card.Header className='break-line'>
                {pod.name}
                </Card.Header>
                <Card.Description>
                <List>            
                <List.Item>
                   <List.Content>
                    <List.Description>
                    <b>Cluster:</b> {pod.parent.cluster_name}
                    </List.Description>
                    </List.Content>
                   </List.Item>       
                   <List.Item>
                   <List.Content>
                    <List.Description>
                    <b>namespace: </b> {pod.namespace}
                    </List.Description>
                    </List.Content>
                   </List.Item>
                   <List.Item>
                   <List.Content>
                    <List.Description>
                    <b>Service Account:</b> {pod.service_account.name}
                    </List.Description>
                    </List.Content>
                   </List.Item>
                   </List>
                </Card.Description>
                </Card.Content>
            </Card>
            </div>
        )
    }

    bcClick(objectName, id) {
        console.log("click on object %s with id %s", objectName, id)
        switch (objectName) {
            case "account":
                if (id === undefined) {
                    window.location.href = "/ui/csp"
                } else {
                    window.location.href = "/ui/csp/" + id
                }
                break
            case "cluster":
                if (id === undefined) {
                    window.location.href = "/ui/csp"
                } else {
                    window.location.href = "/ui/csp/clusters/" + id
                }
                break
        }
       
    }

    displayVulnSummary(summary) {
        if (summary == null) {
            return "No scan summary available"
        }

        return (
           <React.Fragment>
            <Label basic color='red' circular>{summary.num_crit}</Label>
            <Label basic color='orange' circular>{summary.num_high}</Label>
            <Label basic color='yellow' circular>{summary.num_med}</Label>
            <Label basic color='green' circular>{summary.num_low}</Label>
            <Label basic color='grey' circular>{summary.num_others}</Label>
            </React.Fragment>
        )
    
    }

    render() {  

        const { loaded, userCtx, userCtxLoaded, pod, activeIndex } = this.state

        if (loaded && userCtxLoaded) {
            return (
            <div className='Main'>
                <MenuBar activeItem="workloads" userContext={userCtx}></MenuBar>
                <Segment basic textAlign='left'>
                <CspNavBar userContext={userCtx} obj={pod} objType="pod"/>
                <Divider />
                    <Container fluid>
                        <Segment basic>
                        {this.renderPod()}
                        </Segment>
                    </Container>
                    <Container fluid textAlign='left'>
                        <Segment basic>
                            <Header size='medium'>Containers</Header>
                        <Accordion fluid styled>
                        {pod.containers.map((container, index) => (
                            <React.Fragment key={container.name}>
                            <Accordion.Title
                            active={activeIndex === 0}
                            index={index}
                            onClick={this.handleClick}
                            >
                            <Grid columns={2} stackable>
                                <Grid.Column width={10} textAlign='left' className='break-line'>
                                <Icon name='tag' color='blue' /> {container.image} <Icon name='dropdown' />
                                </Grid.Column>
                                <Grid.Column width={6} textAlign='right'>
                                {this.displayVulnSummary(container)}
                                </Grid.Column>
                                
                            </Grid>
                            </Accordion.Title>

                            <Accordion.Content active={activeIndex === index}>
                                <PodContainerCard key={container.id} container={container} />
                            </Accordion.Content>
                            </React.Fragment>
                        ))}
                        </Accordion>
                        </Segment>
                    </Container>
                    </Segment>
            </div>
            ) 
        }            
    }  
}
export default PodView
PodView.propTypes = {  
    podId: PropTypes.string.isRequired, 
}  