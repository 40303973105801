import React, { Component } from 'react'  
import { Table, Icon, Label  } from 'semantic-ui-react'
//import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';  


export class TopVulnObject extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            stats: props.stats,
        }
    }

    componentDidUpdate() {
        console.log('updated account card props')
    }

    displayObjectType(obj) {

        switch (obj.object_type) {
            case "OBJ_IMAGE":
                return (
                    <Icon size='large' name='docker' color='red' />
                )
            case "OBJ_INSTANCE":
                return (
                    <Icon size='large' name='server' color='red' />
                )
        }

    }

    displayObject(obj) {

        switch (obj.object_type) {
            case "OBJ_IMAGE":
                return (
                    <div>
                        <b>{obj.image_repo}/{obj.image_tag}</b><br />
                        {obj.image_registry}
                    </div>
                )
            case "OBJ_INSTANCE":
                return (
                    <div><b>{obj.instance_name}</b></div>
                )
        }

    }

    render () {

       const { stats } = this.state

       console.log("will render top10: %o", stats)

        return (
          <Table celled>
            <Table.Header>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Asset</Table.HeaderCell>
                <Table.HeaderCell>CVEs</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
            {stats.map(stat => (  
                <Table.Row key={stat.object_id}>
                    <Table.Cell textAlign='left' verticalAlign='middle' width={1}>
                    {this.displayObjectType(stat)}
                    </Table.Cell>
                    <Table.Cell>
                    {this.displayObject(stat)}
                    </Table.Cell>
                    <Table.Cell textAlign='right' verticalAlign='middle' width={1}>
                    <Label circular color='red'>{stat.num_cve}</Label>
                    </Table.Cell>
                </Table.Row>             
            ))}
            </Table.Body>
          </Table>
        )
    }

}
export default TopVulnObject  
TopVulnObject.propTypes = {  
    stats: PropTypes.array.isRequired, 
}  