import React, { Component } from 'react'  
import {  Icon, Header, Image, Segment } from 'semantic-ui-react'  

export class AppFailed extends Component {


    render() {  

        return (  
            <div className='Login-body'>
            <div className='Login-box'>

            <Segment basic textAlign='center'>
            <Image rounded size='tiny' src='/logo-stsc.png' centered/>
            <Header as='h3'>An error occured when linking your CSP account - please try again</Header>
            <Icon name="times" circular color='red' size='big'/>
            </Segment>
            

                </div>
            </div>
        )  
    }  
}
export default AppFailed  