import React, { Component } from 'react'  
import { Card} from 'semantic-ui-react'
import Platform from '../libs/platform'

export class CodeReposView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            coderepos: {},
            loaded: false,
        }
     }

    platform = new Platform()
    
    retrieveCodeRepos(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/code_repo/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            this.setState({coderepos : result, loaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        console.log("retrieve code repos list...")
        this.retrieveCodeRepos()
    }

    render() {  

        const { coderepos, loaded } = this.state

        if (loaded) {

            return (
               <Card.Group>
                {coderepos.list.map(repo => (
                        <Card key={repo.id}>
                            <Card.Content>
                                <Card.Header>
                                    {repo.Name}
                                </Card.Header>
                            </Card.Content>
                        </Card>
                ))}
               </Card.Group>
            ) 
        }            
    }  
}
export default CodeReposView
