import React, { Component } from 'react'  
import { MenuBar } from '../components/menubar.js'  
import { Platform } from '../libs/platform.js'
import { Accordion, Divider, Icon, Segment, Button, Confirm } from 'semantic-ui-react'  
import ClusterListView from '../components/clusterList.js';
import InstanceListView from '../components/instanceList.js';
import ContainerImageRepoList from '../components/containerImageRepoList.js';
import CspNavBar from '../components/breadcrumb.js';
import PropTypes from 'prop-types'
import CodeReposView from '../components/codeRepos.js';
import moment from 'moment'

export class InventoryView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            cspId: this.props.cspId,
            inventory: {},
            accountLoaded: false,
            inventoryLoaded: false,
            activeIndex: -1,
            deleteConfirm: false,
        }
     }

    platform = new Platform()

    // process credentials events from the websocket
    messageProcessing(event) {
        const { cspId } = this.state
        console.log("event: %o", event)
        if (event.type == "task" && event.objectId == cspId) {
            // we have a task update for our credential
            this.retrieveAccount()
        }
    }

     // on click - route to the good menu
     handleItemClick = (e, { name }) => {
        this.setState({ activeItem: name })
        window.location.href="/ui/inventory/" + name;
    }

    scanAccount(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const scanURL = "/api/v1/credentials/discover"
        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    onDeleteConfirm = (id) => {
        // close modal
        this.setState({deleteConfirm: false})
        console.log("delete account: " + id)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const deleteURL = "/api/v1/credentials/delete"

        fetch(deleteURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            window.location.href = '/ui/csp'
            },
            (error) => {
            console.log(error);
            }
        )
    }

    onDeleteCancel = () => {
        this.setState({deleteConfirm: false})
    }

    deleteAccount = () => {
        this.setState({deleteConfirm: true})
    }

    delete(id) {
        console.log("delete on cred %o", id)
        this.deleteAccount(id)
    }

    scan(id) {
        console.log("scan on cred %o", id)
        this.scanAccount(id)
    }

    retrieveInventory() {
        const { cspId } = this.state
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"cred_id": cspId})
        };

        fetch("/api/v1/inventory/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            this.setState({inventory : result, inventoryLoaded: true})
            console.log("received inventory: %o", result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    retrieveAccount() {
        const { cspId } = this.state
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": cspId})
        };

        fetch("/api/v1/credentials/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("retrieved account: %o", result)
                this.setState({account : result, accountLoaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }

    accordionHandleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index

        this.setState({ activeIndex: newIndex })
      }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        this.retrieveAccount()
        this.retrieveInventory()
        this.platform.subscribe(this)

    }

    render() {  
        console.log("render with state: %o", this.state)
        const { inventory, cspId, activeIndex, inventoryLoaded, accountLoaded, userCtx, userCtxLoaded, account } = this.state

        
        if (!inventoryLoaded || !accountLoaded || !userCtxLoaded) {
           console.log("loading")
        } else {
            console.log("render account: %o", account)
            let statusIconName = 'question circle outline'
            let statusIconColor = 'grey'
            let scanInProgress = false
            switch (account.last_processing_status) {
                case 'true':
                    statusIconName = 'play circle outline'
                    statusIconColor = 'orange'
                    scanInProgress = true
                    break
                default:
                    statusIconName = 'question circle outline'
                    statusIconColor = 'grey'
                    scanInProgress = false
            }

            let lastScan = 'never scanned...'
            if (account.last_processing_timestamp > 0) {
                lastScan = moment.unix(account.last_processing_timestamp).format('lll')
            }

            console.log("display inventory: %o", inventory)

            return (  
                <div className='Main'>
                    <MenuBar activeItem="csp" userContext={userCtx}></MenuBar>
                    <Segment basic textAlign='left'>
                    <CspNavBar userContext={userCtx} obj={account} objType="account"/>
                    <Divider />
                        Last inventory status: <Icon name={statusIconName} color={statusIconColor}></Icon> {lastScan}
                        <Divider />
                        <Button
                            basic
                            size='tiny'
                            loading={scanInProgress}
                            disabled={scanInProgress}  
                            onClick={() => this.scan(account.id)}>
                            <Icon name='play' color='red'></Icon>
                            Run inventory
                        </Button>
                        <Button basic icon size='tiny' onClick={() => this.delete(account.id)}>
                            <Icon name='trash' color='red'></Icon>
                        </Button>
                        <Confirm
                        open={this.state.deleteConfirm}
                        size="tiny"
                        content='This account will be permanently deleted. Are you sure?'
                        confirmButton="Let's do it"
                        onCancel={this.onDeleteCancel}
                        onConfirm={ () => this.onDeleteConfirm(account.id)}
                        />
                    <Divider />
                    <Accordion fluid styled>

                        <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={this.accordionHandleClick}
                        >
                        <Icon name="server" color='red'/> Instances ({inventory.num_instances}) <Icon name='dropdown' />
                        </Accordion.Title>

                        <Accordion.Content active={activeIndex === 0}>
                        <InstanceListView cspId={cspId}/>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={this.accordionHandleClick}
                        >
                        <Icon name="cubes" color='red'/> Clusters ({inventory.num_clusters}) <Icon name='dropdown' />
                        </Accordion.Title>

                        <Accordion.Content active={activeIndex === 1}>
                        <ClusterListView cspId={cspId}/>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 2}
                        index={2}
                        onClick={this.accordionHandleClick}
                        >
                        <Icon name="docker" color='red'/> Images repositories ({inventory.num_my_repo}) <Icon name='dropdown' />
                        </Accordion.Title>

                        <Accordion.Content active={activeIndex === 2}>
                        <ContainerImageRepoList cspId={cspId}/>
                        </Accordion.Content>

                        <Accordion.Title
                        active={activeIndex === 3}
                        index={3}
                        onClick={this.accordionHandleClick}
                        >
                        <Icon name="code branch" color='red'/> Code repositories ({inventory.num_code_repo}) <Icon name='dropdown' />
                        </Accordion.Title>

                        <Accordion.Content active={activeIndex === 3}>
                        <CodeReposView cspId={cspId}/>
                        </Accordion.Content>

                </Accordion>   
               
                </Segment>
                 
                </div>
            )  
        }
        
    }  
}
export default InventoryView  

InventoryView.propTypes = {  
    cspId: PropTypes.string.isRequired,
}  