import React, { Component } from 'react'
import { Card ,  Grid,  Label, Table, List, Button, Divider, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types';  
import Vulns from './Vulns';
import { Buffer } from 'buffer'
import { filesize } from "filesize";
import moment from 'moment'



export class PodContainerCard extends Component {
    constructor(props) {
        super(props)
        this.state = { 
            activeIndex: 0,
            container: props.container,
            isSbomProcessing: props.container.is_sbom_processing,
        }
    }

    componentDidUpdate(props) {
        console.log('updated pod container card props: %o', props)
        if (props.container.is_sbom_processing != this.state.isSbomProcessing) {
            this.setScanState(props.container.is_sbom_processing)
        }
    }    

    setScanState(state) {
        console.log("change processing to " + state)
        this.setState({isSbomProcessing: state})
    }

    scanSbom(id) {
        this.setState({isSbomProcessing: true})
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({image_id: id}),
        };

        fetch("/api/v1/pod/scan", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log("scan pod: %o", result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    displayEnv(container) {
        if (Object.keys(container.environments).length == 0) {
            return(
                <Label content="no environment variables" />
            )
        }
        return(
                <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Key
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Value
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {Object.keys(container.environments).map(key =>(
                    <Table.Row key={key}>
                        <Table.Cell>
                            {key}
                        </Table.Cell>
                        <Table.Cell>
                        {container.environments[key]}
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
                </Table>
                
        )
    }

    displayArgs(container) {
        if (container.args.length == 0) {
            return (
            <Label content="no arguments" />
            )
        }

        return (
            <Label content={container.args} />
        )

    }

    displayPorts(container) {
        if (container.ports.length == 0) {
            return(
                <Label content="no open ports" />
            )
        }

        return(
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                Port
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                Name
                            </Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                <Table.Body>
                {container.ports.map(port =>(
                    <Table.Row key={Math.random()}>
                        <Table.Cell>
                        {port.protocol}:{port.value}
                        </Table.Cell>
                        <Table.Cell>
                        {port.name}
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
                </Table>
        )

    }

    displayMounts(container) {
        if (container.mounts.length == 0) {
            return(
                <Label content="no mounted storage" />
            )
        }

        return(
                <Table className='break-line' celled fixed >
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Path
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Name
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {container.mounts.map(mount =>(
                    <Table.Row key={Math.random()} className='break-line'>
                        <Table.Cell className='break-line'>
                        {mount.path}
                        </Table.Cell>
                        <Table.Cell className='break-line'>
                        {mount.name}
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
                </Table>
        )

    }

    displayVulns(container) {
        if (container.vuln_data.length == 0) {
            return (
                <Label content="No vulnerabilities found" />
            )
        }

        var decodedReport = Buffer.from(container.vuln_data, 'base64')
        var reportData = decodedReport.toString('utf8')
        var report = JSON.parse(reportData)

        return (
            <Vulns report={report} />
        )
    }


    displayVulnSummary(summary) {
        if (summary == null) {
            return "No scan summary available"
        }

        return (
            <div>
                <div>
                    <Label color='red' circular>{summary.num_crit}</Label>
                    <Label color='orange' circular>{summary.num_high}</Label>
                    <Label color='yellow' circular>{summary.num_med}</Label>
                    <Label color='green' circular>{summary.num_low}</Label>
                    <Label color='grey' circular>{summary.num_others}</Label>
                </div>
            </div>
        )
    
    }


    displayImage(container) {
      
        let status = "Download failed"
        if (container.download_status == "UNAUTHORIZED") {
            status = "Cannot download: not authorized"
        } else if (container.download_status == "DOWNLOADED") {
            status = "Downloaded (" + filesize(container.size) + " bytes)" 
        }

        let sbom = "n/a"
        if (container.is_sbom == true) {
            sbom = "created at " + moment.unix(container.sbom_created_at).format('lll')
        }

        return (
            <List>
                <List.Item>
                <b>Status:</b> {status}
                </List.Item>

                <List.Item> 
                <b>Digest:</b> {container.digest}
                </List.Item>

                <List.Item> 
                <b>SBOM:</b> {sbom}
                </List.Item>
            </List>
        )
    }

    render () {
        const { isSbomProcessing } = this.state
        const container = this.props.container
        console.log("render pod container: %o", container)
        return (
            <Grid columns={2} stackable>
                <Grid.Column width={10}>
                <Card key={Math.random()} fluid>
                <Card.Content>
                <Card.Meta>
                </Card.Meta>
                <Card.Description className='break-line'>
                {this.displayImage(container)}
                <Divider />
                <Button basic size='tiny' 
                    disabled={!container.is_sbom}
                    loading={isSbomProcessing}
                    onClick={() => this.scanSbom(container.image_id)}>
                        <Icon name='play' color='red'></Icon>
                        Scan SBOM
                </Button>
                </Card.Description>
                </Card.Content>
                <Card.Content>
                <Card.Header>
                Security
                </Card.Header>
                <Card.Meta>
                </Card.Meta>
                <Card.Description>
                {this.displayVulns(container)}
                </Card.Description>
                </Card.Content>
                </Card>
                </Grid.Column>

                <Grid.Column width={6}>
                <Card key={Math.random()} fluid>
                <Card.Content>
                <Card.Header>
                Network
                </Card.Header>
                <Card.Meta>
                </Card.Meta>
                <Card.Description>
                {this.displayPorts(container)}
                </Card.Description>
                </Card.Content>

                <Card.Content className='break-line'>
                <Card.Header>
                Storage
                </Card.Header>
                <Card.Meta>
                </Card.Meta>
                <Card.Description>
                {this.displayMounts(container)}
                </Card.Description>
                </Card.Content>

                <Card.Content>
                <Card.Header>
                Arguments
                </Card.Header>
                <Card.Meta>
                </Card.Meta>
                <Card.Description>
                {this.displayArgs(container)}
                </Card.Description>
                </Card.Content>
                <Card.Content>
                <Card.Header>
                Environment
                </Card.Header>
                <Card.Meta>
                </Card.Meta>
                <Card.Description>
                {this.displayEnv(container)}
                </Card.Description>
                </Card.Content>
                </Card>
                </Grid.Column>
            </Grid>
        )
    }

}
export default PodContainerCard  

PodContainerCard.propTypes = {  
    container: PropTypes.object.isRequired, 
}  