import React, { Component } from 'react'
import { Card , Segment, Divider, Container, Header, Grid } from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import { AccountCard } from '../components/accountCard'
import Platform from '../libs/platform'
import AccountModalNew from '../components/accountModalNew';
import CspNavBar from '../components/breadcrumb';
import VulnDashboard from './VulnDashboard'


export class CspAccountView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            credentials: {},
            loaded: false,
            idToIndex: {},
        }
     }

    platform = new Platform()

    // process clusters events from the websocket
    messageProcessing(event) {
        if (event.objectType != "OBJECT_TYPE_CSP_ACCOUNT") {
            return
        }

        switch (event.event) {
            case "OBJECT_ACTION_CREATE":
                console.log("update list of accounts %s")
                this.retrieveCspAccounts()
                break
            default:
                console.log("unhandled event: %o", event)
        }
    }

    providerRedirect(providerName) {
        console.log("redirect for provider %s", providerName)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"provider": providerName})
        };

        fetch("/api/v1/oauth/register", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {

            console.log(result)

            if (result.redirect_url != null && result.redirect_url.length > 0) {
                window.location.href = result.redirect_url;
            } else {
                console.log("provider is unavailable")
            }

          },
          (error) => {
            console.log(error);
          }
        )
    }

    retrieveCspAccount(id, index) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        fetch("/api/v1/credentials/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                let newStateCreds = this.state.credentials
                newStateCreds.list[index] = result
                this.setState(newStateCreds)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    retrieveCspAccounts() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/v1/credentials/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                let idToIndex = {}
                result.list.forEach((cred, index) => {
                    idToIndex[cred.id] = index
                });
            this.setState({credentials : result, loaded: true, idToIndex: idToIndex})
            },
            (error) => {
            console.log(error);
            }
        )
    }


    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        console.log("retrieve accounts...")
        this.platform.subscribe(this)
        this.retrieveCspAccounts()
    }

    render() {  

        const { credentials, loaded, userCtx, userCtxLoaded } = this.state
        console.log("found creds: %o", credentials)


        if (!loaded | !userCtxLoaded) {
            console.log("still not loaded")
        } else {
            return (
                <div className='Main'>
                <MenuBar activeItem="csp" userContext={userCtx}></MenuBar>
                <Container fluid>
                <Segment basic textAlign='left'>
                    <CspNavBar userContext={userCtx} obj={{}} objType="org"/>
                    <Grid columns={3} celled>
                        <Grid.Column largeScreen={3}>
                            <Header>Accounts</Header>
                            <AccountModalNew />
                            <Divider />
                        <Card.Group>
                        {credentials.list.map(cred => (
                            <AccountCard key={cred.id} account={cred}></AccountCard>
                        ))}
                        </Card.Group>
                        </Grid.Column>
                        <Grid.Column largeScreen={10}>
                            <VulnDashboard />
                        </Grid.Column>
                        <Grid.Column largeScreen={3}>

                        </Grid.Column>
                    </Grid>
                    
                    
                </Segment>
                </Container>
                </div>
            ) 
        }    
    }  
}
export default CspAccountView  