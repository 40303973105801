import './App.css';
import { React } from 'react'  
import { LoginForm } from './view/login.js'
import { InventoryView } from './view/inventory'
import { UserView } from './view/user';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import InstanceListView from './components/instanceList';
import { InstanceView } from './view/instance';
import CspAccountView from './view/cspAccount';
import { useParams } from 'react-router-dom';
import OrganizationListView from './view/organizationList';
import OrganizationSettingsView from './view/organizationSettings'
import ContainerImageTagList from './view/containerImageTagList';
import ImageView from './view/image';
import PodListView from './view/podList';
import PodView from './view/pod'
import ClusterView from './view/cluster';
import AppSuccess from './view/app-success';
import AppFailed from './view/app-failed';
import TriageIssuesView from './view/triage_issues';
import MapView from './view/map';
import VulnDashboard from './view/VulnDashboard.js';

// depending on the type of deployment (saas vs appliance)
// we return a different login form
function LoginFormView() {

  return (
    <LoginForm isLoginPass={true} isOauth={true}/>
  )
}


function AppRouteInstanceView() {
  let params = useParams();
  return (
    <InstanceView instanceId={params.instanceId}/>
  )
}

function AppRouteContainerImageTagView() {
  let params = useParams();
  return (
    <ContainerImageTagList repositoryId={params.repositoryId}/>
  )
}

function AppRouteTagView() {
  let params = useParams();
  return (
    <ImageView tagId={params.tagId}/>
  )
}

function AppRouteClusterView() {
  let params = useParams();
  return (
    <ClusterView clusterId={params.clusterId}/>
  )
}

function AppRoutePodView() {
  let params = useParams();
  return (
    <PodView podId={params.podId}/>
  )
}

function AppRouteCspAccountView() {
  let params = useParams();
  return (
    <InventoryView cspId={params.cspId}/>
  )
}

function AppRouteCspMapView() {
  let params = useParams();
  return (
    <MapView cspId={params.cspId}/>
  )
}

function AppRouteTriageView() {
  let params = useParams();
  
  console.log("will triage object %s/%s with key %s", params.objType, params.objId, params.key)
}


function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<LoginFormView />} />
          <Route path='/ui' element={<OrganizationListView />} />
          <Route path='/app-success' element={<AppSuccess />} />
          <Route path='/app-failed' element={<AppFailed />} />

          <Route path='/ui/map' element={<MapView />} />
          <Route path='/ui/vuln/dashboard' element={<VulnDashboard />} />

          <Route path='/ui/csp' element={<CspAccountView />} />
          <Route path='/ui/csp/:cspId' element={<AppRouteCspAccountView />} />
          <Route path='/ui/csp/:cspId/map' element={<AppRouteCspMapView />} />
          <Route path='/ui/csp/repositories/:repositoryId' element={<AppRouteContainerImageTagView />} />
          <Route path='/ui/csp/tag/:tagId' element={<AppRouteTagView />} />
          <Route path='/ui/csp/clusters/:clusterId' element={<AppRouteClusterView />} />

          <Route path='/ui/organization/settings' element={<OrganizationSettingsView />} />     

          <Route path='/ui/workloads' element={<InstanceListView />} />
          <Route path='/ui/workloads/instances' element={<InstanceListView />} />
          <Route path='/ui/workloads/pods' element={<PodListView />} />
          <Route path='/ui/workloads/instances/:instanceId' element={<AppRouteInstanceView />} />
          <Route path='/ui/workloads/pods/:podId' element={<AppRoutePodView />} />

          <Route path='/ui/triage' element={<TriageIssuesView />} />
          <Route path='/ui/triage/:objType/:objId/:key' element={<AppRouteTriageView />} />


          <Route path='/ui/user' element={<UserView />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
