import React, { Component } from 'react'
import { Card , Segment, Divider } from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import ContainerImageTagCard from '../components/containerImageTagCard'
import Platform from '../libs/platform'
import PropTypes from 'prop-types';  
import CspNavBar from '../components/breadcrumb'

export class ContainerImageTagList extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            tags: {},
            loaded: false,
            repositoryId: this.props.repositoryId,
            idToIndex: {}
        }
     }

    platform = new Platform()

    // process clusters events from the websocket
    messageProcessing(event) {

        if (event.objectType != "OBJECT_TYPE_IMAGE") {
            return
        }

        const tagIndex = this.state.idToIndex[event.objectId]


        console.log("look for key %s in index", event.objectId)
        switch (event.event) {
            case "OBJECT_ACTION_UPDATE":

                console.log("update for tag %s", event.objectId)
                this.retrieveTag(event.objectId, tagIndex)
                return
            default:
                console.log("unhandled event: %o", event)
        }
    }


    retrieveTag(id, index) {

        console.log("will refresh tag %s at index %d", id, index)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const tagURL = '/api/v1/containers/tag/read'

        fetch(tagURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                let newList = this.state.tags
                newList.list[index] = result
                this.setState(newList)
                console.log("state updated at index %d with new tags props: %o", index, result)
            },
            (error) => {
            console.log("cannot retrieve tags: %o", error);
            }
        )
    }


    retrieveTags(id) {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({repositoryId: id}),
        };

        fetch("/api/v1/containers/tag/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {

                let idToIndex = {}
                result.list.forEach((tag, index) => {
                    console.log("set tag id %s to index %d", tag.id, index)
                    idToIndex[tag.id] = index
                });

                this.setState({tags : result, loaded: true, idToIndex: idToIndex})
                console.log("received tags: %o", result)
            },
            (error) => {
            console.log(error);
            }
        )
    }
    

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        const { repositoryId } = this.state
        console.log("retrieve tags for repository: %s", repositoryId)
        this.retrieveTags(repositoryId)
        this.platform.subscribe(this)
    }

    bcClick(objectName, id) {
        console.log("click on object %s with id %s", objectName, id)
        switch (objectName) {
            case "account":
                if (id === undefined) {
                    window.location.href = "/ui/csp"
                } else {
                    window.location.href = "/ui/csp/" + id
                }
        }
       
    }

    render() {  
        const { loaded, userCtx, userCtxLoaded } = this.state
        if (!loaded || !userCtxLoaded) {
            console.log("still not loaded")
        } else {
            const { tags } = this.state
            
            return (
                <div className='Main'>
                <MenuBar activeItem="csp" userContext={userCtx}></MenuBar>
                <Segment basic textAlign='left'>
                <CspNavBar userContext={userCtx} obj={tags} objType="tags" />
                <Divider />
                    <Card.Group>
                        {tags.list.map(tag => (
                            <ContainerImageTagCard key={tag.id} tag={tag} />
                        ))}
                    </Card.Group>
                </Segment>
                </div>
            ) 
        }    
    }  
}
export default ContainerImageTagList


ContainerImageTagList.propTypes = {  
    repositoryId: PropTypes.string.isRequired, 
}  