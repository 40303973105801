import React, { Component } from 'react'  
import { Card , Icon, Grid } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';  


export class AccountCard extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            account: props.account,
            deleteConfirm: false,
        }
    }

    componentDidUpdate() {
        console.log('updated account card props')
    }

    render () {

        const account = this.props.account
        console.log("render account: %o", account)

        let inventoryURL = '/ui/csp/' + account.id


        return (
            <Card key={account.id}  as={Link} to={inventoryURL}>
                <Card.Content>
                <Card.Header>
                    <Grid columns={2}>
                        <Grid.Column width={13}>
                        {account.name}
                        </Grid.Column>
                        <Grid.Column width={3}>
                            <Icon name="key" color='red' size='large' />
                        </Grid.Column>
                    </Grid>
                </Card.Header>
                <Card.Meta>
                {account.vendor}
                </Card.Meta>
                </Card.Content>

            </Card>
        )
    }

}
export default AccountCard  

AccountCard.propTypes = {  
    account: PropTypes.object.isRequired, 
}  