import React, { Component } from 'react'
import { Button, Card , Label, Icon, Grid } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import moment from 'moment'
import PropTypes from 'prop-types';  


export class ContainerImageTagCard extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            tag: props.tag,
            scanInProgress: false,
        }
        if (props.tag.num_processing > 0) {
            this.state.scanInProgress = true
            console.log("set scan in progress")
        }
    }

    scan(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const scanURL = "/api/v1/containers/tag/scan"

        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    componentDidUpdate() {
        const tag = this.props.tag
        console.log('updated tag card props: %o', tag)
    }

    repoIsOwner(repo) {
        if (repo.is_managed) {
            return (
                <Label color='red' tag>Managed</Label>
            )
        } else {
            return (
                <Label color='grey' tag>3rd party</Label>
            )
        }
    }

    displayImageDetails(image) {

        if (image == null) {
            return (
                <Label basic circular color='red'>Download error</Label>
            )
        }

        let buildAt = moment.unix(image.build_at).format('lll')

        return (
           <small>Built at: {buildAt}</small>
        )
    }


    displaySecurity(image) {

        if (image == null) {
            return "No scan summary available"
        }

        if (image.security == null) {
            return "No security summary available"
        }

        let summary = image.security

        let lastScan = moment.unix(summary.created_at).format('lll')


        if (summary.num_crit == 0 &&
        summary.num_high == 0 &&
        summary.num_med == 0 &&
        summary.num_low == 0 &&
        summary.num_others == 0) {

            return (
                <React.Fragment>
                <small>{lastScan}</small>
                <br/>
                <Icon size='big' name='check circle' color='green'/>
                <br/>
                <small>No Vulnerabilities found!</small>
                </React.Fragment>
            )

        }

        return (
            <div>
                <div>
                   <small>{lastScan}</small>
                </div>
                <div>
                    <Label basic color='red' circular>{summary.num_crit}</Label>
                    <Label basic color='orange' circular>{summary.num_high}</Label>
                    <Label basic color='yellow' circular>{summary.num_med}</Label>
                    <Label basic color='green' circular>{summary.num_low}</Label>
                    <Label basic color='grey' circular>{summary.num_others}</Label>
                </div>
            </div>
        )
    }
    
    displayImageSecurity(image) {

        if (image == null || image === undefined || image.security == null) {
            return (
                <Label color='grey' size='massive'>-</Label>
            )
        }

        let security = image.security
        return (
            <div>
            <Label color='red' size='massive'>{security.grade}</Label>
            </div>
        )
    }

    render () {
        const tag = this.props.tag
        console.log("render tag: %o", tag)

        let isProcessing = false
        if (tag.num_processing > 0) {
            isProcessing = true
        }

        let tagImageURL = ''
        if (tag.current_image != null){
            tagImageURL = '/ui/csp/tag/' + tag.id
        }


        return (
            <Card key={tag.id}>
                <Card.Content>
                <Card.Header>
                    <Grid columns={2}>
                        <Grid.Column width={13} textAlign='left'>
                        {tag.version}
                        </Grid.Column>
                        <Grid.Column width={3} className='break-line'>
                        <Icon name="tag" color='red' size='large'/>
                        </Grid.Column>
                    </Grid>
                </Card.Header>
                <Card.Meta>

                </Card.Meta>
                <Card.Description>
                {this.displayImageDetails(tag.current_image)}
                </Card.Description>
                </Card.Content>
                <Card.Content>
                <Card.Description as={Link} to={tagImageURL}>
                {this.displaySecurity(tag.current_image)}
                </Card.Description>
                </Card.Content>
                <Card.Content>
                <Button 
                    basic
                    size='tiny'
                    icon color='red'
                    labelPosition='left'
                    onClick={() => this.scan(tag.id)}
                    loading={isProcessing}
                    disabled={isProcessing}>
                <Icon name='play'></Icon>
                Scan
                </Button>
                </Card.Content>

            </Card>
        )
    }

}
export default ContainerImageTagCard  

ContainerImageTagCard.propTypes = {  
    tag: PropTypes.object.isRequired, 
}  