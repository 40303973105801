import React, { Component } from 'react'  
import { Table, Label, Pagination } from 'semantic-ui-react'
import { InstancePackageVulns} from '../components/instancePackageVulns'
import PropTypes from 'prop-types';  
import _ from 'lodash'

export class Vulns extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            loaded: false,
            offset: 0,
            limit: 10,
            report: this.props.report,
        }
    }


    openDetails(pkg) {
        console.log("open details for " + pkg)
    }

    vulnColor(severity) {
        switch (severity) {
            case 5:
                return 'red'
            case 4:
                return 'orange'
            case 3:
                return 'yellow'
            case 2:
                return 'green'
            default:
                return 'grey'
        }
    }

    cvssColor(score) {
        if (score > 9) {
            return 'red'
        }
        else if (score >= 7 && score < 9) {
            return 'orange'
        }
        else if (score >= 4 && score < 7) {
            return 'yellow'
        }
        else {
            return 'green'
        }
    }

    handlePaginationClick = (event, data) => {
        let newOffset = (data.activePage - 1) * 10
        console.log("will move to offset: " + newOffset)
        this.setState({offset: newOffset})
    }

    displayCvss(score, version) {

        if (score == 0) {
            return (
                <React.Fragment>
                <Label basic circular content="Not available" />
                </React.Fragment>
                )
        }

        return (
            <React.Fragment>
            <Label basic circular color={this.cvssColor(score)} >{score}</Label> (v{version})
            </React.Fragment>
        )
    }

    componentDidMount() {
    }

    render () {

        const { report} = this.state

        if (report.Packages == null || report.Packages === undefined) {
            return
        }

        let pkg = report.Packages
        pkg = _.orderBy(pkg, ['MaxSeverity'], 'desc')
        let totalPages = Math.round(Object.keys(report.Packages).length / this.state.limit)

        return (
            <div>
            <Table celled fixed singleLine>
                <Table.Header>
                    <Table.Row key="hdr">
                        <Table.HeaderCell width={1}>
                            Severity
                        </Table.HeaderCell>
                        <Table.HeaderCell width={5}>
                            Package
                        </Table.HeaderCell>
                        <Table.HeaderCell width={2} textAlign="left">
                            Max CVSS Score
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign='left' width={2}>
                            Vulnerabilities
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {Object.keys(pkg).slice(this.state.offset,this.state.offset + this.state.limit).map(key => (
                    <Table.Row key={Math.random()}>
                        <Table.Cell>
                        <Label basic color={this.vulnColor(pkg[key].MaxSeverity)} circular>{pkg[key].MaxSeverity}</Label>
                        </Table.Cell>
                        <Table.Cell>
                        {pkg[key].Name}-{pkg[key].Version}
                        </Table.Cell>
                        <Table.Cell textAlign="left">
                        {this.displayCvss(pkg[key].MaxCvssScore,pkg[key].MaxCvssVersion)}
                        </Table.Cell>
                        <Table.Cell textAlign='left' width={2}>
                        <InstancePackageVulns pkg={pkg[key]} vulndb={report.Vulnerabilities} />
                        </Table.Cell>
                    </Table.Row>
                ))}
                </Table.Body>
            </Table>

            <Pagination
                defaultActivePage={1}
                floated="right"
                offset={this.state.offset}
                onPageChange={this.handlePaginationClick}
                boundaryRange={1}
                totalPages={totalPages}
                limit={this.state.limit}
            />
            </div>
        )

    }

}
export default Vulns  
Vulns.propTypes = {  
    report: PropTypes.object.isRequired,
}  