import React, { Component } from 'react'  
import { Button, Icon, Item, ItemContent, Image, Segment, Card, Container, Form, Input, Header } from 'semantic-ui-react'  

export class LoginForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            statusLoaded: false,
            auth: {},
            oauth: {},
            isOauth: true,
            isLoginPass: true,
            formLogin: "",
            formPassword: "",
            isFormLoginFalse: true,
            isFormPassFalse: true,
        }
    }

    getStatus() {

        console.log("get platform status")

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/v1/healthz", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            this.setState({statusLoaded: true, auth: result.auth_mechanism, oauth: result.oauth_mechanism})
            console.log(result)
            
          },
          (error) => {
            console.log(error);
          }
        )
    }

    providerRedirect(providerName) {

        console.log("redirect for provider %s", providerName)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"provider": providerName})
        };

        fetch("/api/v1/oauth/signin", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {

            console.log(result)

            if (result.redirect_url != null && result.redirect_url.length > 0) {
                window.location.href = result.redirect_url;
            } else {
                console.log("provider is unavailable")
            }

          },
          (error) => {
            console.log(error);
          }
        )
    }

    saveLogin = (event, data) => {
        if (data.value.length > 0) {
            this.setState({isFormLoginFalse: false})
        } else {
            this.setState({isFormLoginFalse: true})
        }
        this.setState({formLogin: data.value})
    }

    savePassword = (event, data) => {
        if (data.value.length > 0) {
            this.setState({isFormPassFalse: false})
        } else {
            this.setState({isFormPassFalse: true})
        }
        this.setState({formPassword: data.value})
    }

    doLogin() {

        console.log("do login %s with password", this.state.formLogin)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"username": this.state.formLogin, "password": this.state.formPassword})
        };

        fetch("/api/v1/auth/login", requestOptions)
        .then(res => res.json())
        .then(
          (result) => {
            window.location.href = "/ui";
            console.log(result)

          },
          (error) => {
            console.log(error);
          }
        )
    }


    displayLoginPass() {

        if (this.state.auth["login"] != true) {
            return
        }

        return (
            <Card>
            <Card.Content extra>
                 <Form>
                    <Form.Field>
                    <label>Login</label>
                    <Input 
                        placeholder='Login' 
                        onChange={this.saveLogin}
                        error={this.state.isFormLoginFalse}
                    />
                    </Form.Field>
                    <Form.Field>
                    <label>Password</label>
                    <Input 
                        type='password' 
                        placeholder='Password' 
                        onChange={this.savePassword}
                        error={this.state.isFormPassFalse}
                    />
                    </Form.Field>
                    <Button 
                        basic type='submit' 
                        color='green' 
                        onClick={() => this.doLogin()} 
                        disabled={this.state.isFormLoginFalse || this.state.isFormPassFalse}
                    >Login</Button>
                </Form>
            </Card.Content>
            <Card.Content>
                <a>Forgot password?</a><br />
            </Card.Content>
            </Card>
        )
    }

    displayOauthGoogle() {

        if (this.state.oauth["GOOGLE"] != true) {
            return
        }
        
        return (
            <Item>
                <ItemContent>
                <Button basic fluid icon onClick={() => this.providerRedirect('GOOGLE')} labelPosition='left'>
                        <Icon name='google' color='red'/>
                        Google
                    </Button>
                </ItemContent>
            </Item>
        )

    }

    displayOauthMicrosoft() {

        if (this.state.oauth["MICROSOFT"] != true) {
            return
        }
        
        return (
            <Item>
                <ItemContent>
                <Button basic fluid icon onClick={() => this.providerRedirect('MICROSOFT')} labelPosition='left'>
                        <Icon name='microsoft' color='blue'/>
                        Microsoft
                    </Button>
                </ItemContent>
            </Item>
        )

    }

    displayOauthGithub() {

        if (this.state.oauth["GITHUB"] != true) {
            return
        }
        
        return (
            <Item>
                <ItemContent>
                <Button basic fluid icon onClick={() => this.providerRedirect('GITHUB')}  labelPosition='left'>
                        <Icon name='github' color='black'/>
                        Github
                    </Button>
                </ItemContent>
            </Item>
        )

    }

    displayOauthGitlab() {

        if (this.state.oauth["GITLAB"] != true) {
            return
        }
        
        return (
            <Item>
                <ItemContent>
                <Button basic fluid icon onClick={() => this.providerRedirect('GITLAB')} labelPosition='left'>
                        <Icon name='gitlab' color='red'/>
                        Gitlab
                    </Button>
                </ItemContent>
            </Item>
        )

    }

    displayAuthSSO() {

        if (this.state.auth["sso"] != true) {
            return
        }
        
        return (
            <Card.Content>
            <Button basic fluid icon labelPosition='left'>
                <Icon name='key' color='black'/>
                SSO
            </Button>
            </Card.Content>

        )

    }

    displayOauth() {

        if (this.state.oauth == null || Object.keys(this.state.oauth).length <= 0) {
            return
        }

        return (
            <Card>
                <Card.Content extra>
                    <label>Signin with</label>
                    <Item.Group>
                    {this.displayOauthGoogle()}
                    {this.displayOauthMicrosoft()}
                    {this.displayOauthGithub()}
                    {this.displayOauthGitlab()}
                    </Item.Group>
                </Card.Content>
                {this.displayAuthSSO()}
            </Card>
           
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.getStatus()
    }

    render() {  

        const {statusLoaded} = this.state

        if (!statusLoaded) {
            return (
                <Segment basic>
                    Loading...
                </Segment>
            )
        }

        return ( 
            <Segment basic>
            <Image src='logo.jpg' size='tiny' rounded centered></Image>
            <Header>Aphid.cloud</Header>
            <br />
            <Container fluid>
                <Card.Group centered>
                    {this.displayLoginPass()} 
                    {this.displayOauth()}   
                </Card.Group>
            </Container>
            </Segment>
        )  
    }  
}
export default LoginForm
