import React, { Component } from 'react'  
import {  Icon, Header, Image, Segment } from 'semantic-ui-react'  

export class AppSuccess extends Component {


    render() {  

        return (  
            <div className='Login-body'>
            <div className='Login-box'>

            <Segment basic textAlign='center'>
            <Image rounded size='tiny' src='/logo-stsc.png' centered/>
            <Header as='h3'>You have setup your new CSP account successfully</Header>
            <Icon name="check" circular color='green' size='big'/>
            </Segment>
            

                </div>
            </div>
        )  
    }  
}
export default AppSuccess  