import React, { Component } from 'react'
import { Card , Container, Segment} from 'semantic-ui-react'
import ContainerImageRepoCard from './containerImageRepoCard'
import Platform from '../libs/platform'
import PropTypes from 'prop-types';  

import _ from 'lodash'

export class ContainerImageRepoList extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            cspId: this.props.cspId,
            repos: {},
            loaded: false,
            offset: 0,
            numCard: 100,
        }
     }

    platform = new Platform()

    retrieveRepos() {

        const { cspId } = this.state

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"credential_id": cspId})
        };

        fetch("/api/v1/containers/repository/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {

                result.list = _.orderBy(result.list, ['is_managed'], 'desc') 
                console.log("got repos %o", result)              
                this.setState({repos : result, loaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }
    

    // This view require to be authenticated 
    componentDidMount() {
        //this.platform.setupPlatformContext(this)
        console.log("retrieve images...")
        this.retrieveRepos()
    }

    render() {  
        const { loaded, repos } = this.state
        if (!loaded ) {
            console.log("still not loaded")
        } else {


            return (
                <Container fluid>
                    <Segment basic textAlign='left'>
                    <Card.Group>
                            {repos.list.map(repo => (
                                <ContainerImageRepoCard key={repo.id} repo={repo}/>
                            ))}
                    </Card.Group>
                    </Segment>
                </Container>
            ) 
        }    
    }  
}
export default ContainerImageRepoList

ContainerImageRepoList.propTypes = {  
    cspId: PropTypes.string.isRequired, 
}  