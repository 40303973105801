import React, { Component } from 'react'  
import { MenuBar } from '../components/menubar.js'  
import { Platform } from '../libs/platform.js'
import { Card , Button, Label, Segment, Icon} from 'semantic-ui-react'

export class TriageIssuesView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            groups: {},
            loaded: false,
        }
     }

    platform = new Platform()
   
    retrieve() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"num_responses": 10})
        };

        fetch("/api/v1/todo/group", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("triage groups: %o", result)
                this.setState({groups : result.group, loaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }

    getGradeColor(grade) {
            switch (grade) {
                case 'A':
                case 'B':
                    return 'green';
                case 'C':
                case 'D':
                    return 'orange'
                case 'E': 
                case 'F':
                    return 'red'
                default:
                    return 'grey'
           }
    }

    getObjectIcon(objType) {
        switch (objType) {
            case "EXPOSURE_URL":
                return (<Icon name="globe" color='blue'/>)
            case "CLUSTER":
                return (<Icon name="cubes" color='blue'/>)
            case "IMAGE":
                return (<Icon name="docker" color='blue'/>)
        }
    }

    triage(objType, id, key) {
        let url = "/ui/triage/" +objType+ "/" +id+ "/"+ key
        window.location.href = url
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        console.log("retrieve issues...")
        this.retrieve()
    }

    render() {  

        const { groups, loaded, userCtx } = this.state
        if (!loaded) {
            console.log("still not loaded")
        } else {
            return (  
                <div className='Main'>
                <MenuBar userContext={userCtx} activeItem='issues'></MenuBar>
                <Segment basic textAlign='left'>
                <Card.Group>
                {groups.map(group => (
                    <Card key={group.tmp_id}>
                        <Card.Content className='break-line'>
                        <Card.Header>
                        {group.key_name}
                        </Card.Header>
                        <Card.Meta>
                        {group.reasons.length} occurence(s)
                        </Card.Meta>
                        <Card.Description>
                            <Label size='large' color={this.getGradeColor(group.grade)}>{group.grade}</Label>
                        </Card.Description>
                        </Card.Content>
                        <Card.Content className='break-line'>
                        <Card.Description>
                            <small>{this.getObjectIcon(group.object_type)}{group.object_name}</small>
                        </Card.Description>
                        </Card.Content>
                        <Card.Content className='break-line'>
                       
                        <Card.Description>
                            <Button basic color='blue' size='tiny' onClick={() => this.triage(group.object_type, group.object_id, group.key)}>triage</Button>
                        </Card.Description>
                        </Card.Content>
                    </Card>
                ))}
                </Card.Group>
                </Segment>
                </div>
            )  
        }
    }  
}
export default TriageIssuesView  