import React, { Component } from 'react'  
import { Card , Button, Label, Icon, Dropdown, Grid} from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import PropTypes from 'prop-types';  


export class ClusterCard extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            cspId: props.cspId,
            cluster: props.cluster,
            scanInProgress: false,
        }
        if (props.cluster.num_processing > 0) {
            this.state.scanInProgress = true
            console.log("set scan in progress")
        }
    }

    scan(id) {
        const scanURL = "/api/v1/clusters/" + id + "/scan"
        fetch(scanURL)
        .then(res => res.json())
        .then(
            (result) => {
            console.log("create scan: %o: ", result)
            },
            (error) => {
            console.log("cannot create scan: %o", error);
            }
        )
    }

    delete(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };
        const scanURL = "/api/v1/clusters/delete"
        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log("deleted scan: %o: ", result)
            },
            (error) => {
            console.log("cannot delete scan: %o", error);
            }
        )
    }

    clusterSummaryDisplay = (summary) => {

        let lastScan = 'Never scanned...'

        if (summary == null) {
            return (
                <div>
                Never Scanned...
                </div>
            )
        }

       
        lastScan = moment.unix(summary.timestamp).format('lll')
        
        return (
            <div>
                {/*
                <Label size='massive' color={gradeColor}>{gradeValue}</Label>
                <Divider />
                */}
                <small>Last scan: {lastScan}</small><br/>
                <Label basic circular>Pods: {summary.num_pods}</Label>
                <Label basic circular>Services: {summary.num_services}</Label>
                <Label basic circular>Ingress: {summary.num_ingresses}</Label>
                
            </div>
        )
    }

    componentDidUpdate() {
        console.log('cluster card updated with %o', this.state.cluster)
    }

    render () {
        const { cluster } = this.state

        let clusterProcessing = false
        if (cluster.num_processing > 0) {
            console.log("scan in progress ...")
            clusterProcessing = true
        }else {
            console.log("no scan in progress ...")
        }
        console.log("render cluster: %o", cluster)

        let clusterURL = "/ui/csp/clusters/" + cluster.id

        return (
            <Card key={cluster.id}>
                <Card.Content as={Link} to={clusterURL}>
                <Card.Header>
                    <Grid columns={2}>
                        <Grid.Column width={13} className='break-line'>
                        {cluster.meta.name}
                        </Grid.Column>
                        <Grid.Column width={3}>
                        <Icon name="cubes" color='red' size='large'/>
                        </Grid.Column>
                       
                    </Grid>
                </Card.Header>
                <Card.Meta>
                {cluster.meta.provider}
                </Card.Meta>
                </Card.Content>
                <Card.Content as={Link} to={clusterURL}>
                <Card.Description>
                {this.clusterSummaryDisplay(cluster.last_scan)}
                </Card.Description>
                </Card.Content>
                <Card.Content textAlign='right' extra>
                <Card.Description>
                <Button 
                    size='tiny'
                    basic
                    onClick={() => this.scan(cluster.id)}
                    loading={clusterProcessing}
                    disabled={clusterProcessing}>
                <Icon name='play' color='red'></Icon>
                Scan cluster
                </Button>
                <Dropdown icon='ellipsis horizontal' button={true} className="tiny icon" basic direction='left'>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            onClick={ () => this.delete(cluster.id)}
                        >
                        Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </Card.Description>
                </Card.Content>
            </Card>
        )
    }

}
export default ClusterCard  

ClusterCard.propTypes = {  
    cluster: PropTypes.object.isRequired, 
    cspId: PropTypes.string.isRequired,
}  