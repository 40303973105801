import React, { Component } from 'react'
import { Segment, Header, Grid, Loader, Container } from 'semantic-ui-react'
import Platform from '../libs/platform'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from 'recharts';  
import TopVulnObject from '../components/topVulnObject'
import TopVulnPackage from '../components/topVulnPackage'

export class VulnDashboard extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            vuln: {},
            loaded: false,
            idToIndex: {},
        }
     }

    platform = new Platform()

    retrieveVulnDashboard() {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/v1/vulnerability/dashboard/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({vuln : result, loaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        console.log("retrieve vuln history...")
        this.retrieveVulnDashboard()
    }

    render() {  

        const { vuln, loaded, userCtxLoaded } = this.state
        console.log("found vuln: %o", vuln)


        if (!loaded | !userCtxLoaded) {
            return (
                <div className='Main'>
                    <Segment basic>
                    <Loader active inline='centered' />                        
                    </Segment>
                </div>
            )
        } else {
            return (
                    <Container>                   
                    <Grid columns={1} >
                        <Grid.Column>
                            <Header>Last 30 days number of vulnerabilities</Header>
                            <Segment>
                            <ResponsiveContainer height={250} width="100%">
                                <LineChart

                                title='Vulnerabilities'
                                data={vuln.vuln_history.history}
                                margin={{
                                    top: 20,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                                >
                                <CartesianGrid strokeDasharray="2 2" />
                                <XAxis dataKey="day"/>
                                <YAxis dataKey="num_vuln_id"/>
                                <Tooltip />
                                <Legend />
                                <Line type="monotone" dataKey="num_vuln_id" strokeWidth={1} stroke="#FF3633"  name='CVEs' />
                                <Line type="monotone" dataKey="num_vuln_package" strokeWidth={1} stroke="#1C1244" name='Vulnerable Packages' />
                                </LineChart>
                            </ResponsiveContainer>
                            </Segment>
                        </Grid.Column>
                    </Grid>                  
                    <Grid columns={2} >
                        <Grid.Column width={8}>
                            <Header>Top 5 vulnerable package impact (Critical and High)</Header>
                            <TopVulnPackage stats={vuln.vuln_history.top5package}/>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Header>Top 5 most vulnerable assets (Critical and High)</Header>
                            <TopVulnObject stats={vuln.vuln_history.top5object}/>
                        </Grid.Column>
                    </Grid>
                    </Container>
            ) 
        }    
    }  
}
export default VulnDashboard  