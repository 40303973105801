import React, { Component } from 'react'
import { Container } from 'semantic-ui-react'
import PropTypes from 'prop-types'
import Platform from '../libs/platform'

export class MapView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            cspId: this.props.cspId,
            loaded: false,
        }
     }

    platform = new Platform()

    retrieveMap() {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"object_id": this.state.cspId, "object_type": "NODE_CREDENTIAL"})
        };

        fetch("/api/v1/map/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("map: %o", result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        this.retrieveMap()
    }

    render() {  
        const { pods, loaded, userCtxLoaded } = this.state
        console.log("found pods: %o", pods)
        if (!loaded || !userCtxLoaded) {
            console.log("still not loaded")
        } else {

            return (
              <Container></Container>
            ) 
        }    
    }  
}
export default MapView


MapView.propTypes = {  
    cspId: PropTypes.string.isRequired,
}  