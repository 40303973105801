import React, { Component } from 'react'
import { Card ,  Label } from 'semantic-ui-react'
import PropTypes from 'prop-types';  
import { Link } from 'react-router-dom'

export class PodCard extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            pod: props.pod,
        }
    }

    componentDidUpdate() {
        console.log('updated instance card props')
    }

    displayVulnSummary(summary) {
        if (summary == null) {
            return "No scan summary available"
        }

        return (
            <div>
                <div>
                    <Label basic color='red' circular>{summary.num_crit}</Label>
                    <Label basic color='orange' circular>{summary.num_high}</Label>
                    <Label basic color='yellow' circular>{summary.num_med}</Label>
                    <Label basic color='green' circular>{summary.num_low}</Label>
                    <Label basic color='grey' circular>{summary.num_others}</Label>
                </div>
            </div>
        )
    
    }

    render () {

        const pod = this.props.pod
        console.log("render pod: %o", pod)
        let podDetailsURL = '/ui/workloads/pods/' + pod.id

        return (
            <Card key={pod.id} as={Link} to={podDetailsURL}>
                <Card.Content>
                <Card.Header className='break-line'>
                {pod.name}
                </Card.Header>
                <Card.Meta>
                namespace: {pod.namespace}
                </Card.Meta>
                </Card.Content>
        
                <Card.Content>
                <Card.Header>
                {this.displayVulnSummary(pod)}
                </Card.Header>
                <Card.Description>
                    <Label circular>{pod.containers.length} container(s)</Label>   
                </Card.Description>
                </Card.Content>

            </Card>
        )
    }

}
export default PodCard  

PodCard.propTypes = {  
    pod: PropTypes.object.isRequired, 
}  