import React, { Component } from 'react'  
import { Button, Icon, Container, Modal, Input, Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'

export class OrganizationNewInviteModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            saveInvitationUsername: null,
        }
    }

    toggleState = () => {
        console.log("change modal state")
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        })
    }

   
    saveInvitationEmail = (event, data) => {
        this.setState({saveInvitationUsername: data.value})
    }

    sendInvitation (){
        console.log("send invitation for %s", this.state.saveInvitationUsername)


        if (this.state.saveInvitationUsername.length > 0) {
                
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        "username": this.state.saveInvitationUsername,
                        "type": "ADMIN"})
                };
    
                const scanURL = "/api/v1/organizations/invitation/create"
    
                fetch(scanURL, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("created invitation: %o", result)
                        this.props.callerObj.retrieveInvitations()
                    },
                    (error) => {
                    console.log(error);
                    }
                )


            }

        this.toggleState()
    }

    render () {

        return (
            <Container>
                <Modal
                    onClose={() => this.toggleState()}
                    onOpen={() => this.toggleState()}
                    open={this.state.isModalOpen}
                    trigger={<Button basic size='small'><Icon color='green' name="plus circle" />Invite a user</Button>}
                    size="tiny"
                >
                <Modal.Header>Invite new user</Modal.Header>
                <Modal.Content>
                <Modal.Description>
                <Form>
                    <Form.Field>
                        <Input
                            label={{ content: 'Username' }}
                            labelPosition='left'
                            placeholder='Email of the user you want to invite...'
                            onChange={this.saveInvitationEmail}
                            fluid
                        />
                    </Form.Field>
                </Form>                       
                </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                <Button basic color='red' onClick={() => this.toggleState()}>
                Cancel
                </Button>
                <Button basic color="green" onClick={() => this.sendInvitation()}>
                Add
                </Button>
                </Modal.Actions>
            </Modal>
            </Container>
        )
        
    }
}

export default OrganizationNewInviteModal


OrganizationNewInviteModal.propTypes = {  
    callerObj: PropTypes.object.isRequired
}  