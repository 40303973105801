import React, { Component } from 'react'  
import { Table, Label  } from 'semantic-ui-react'
//import { Link } from 'react-router-dom'
import PropTypes from 'prop-types';  


export class TopVulnPackage extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            stats: props.stats,
        }
    }

    componentDidUpdate() {
        console.log('updated account card props')
    }


    render () {

       const { stats } = this.state

       console.log("will render top10: %o", stats)

        return (
          <Table celled> 
            <Table.Header>
                <Table.HeaderCell>Package</Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>Impacted Assets</Table.HeaderCell>
                <Table.HeaderCell>CVEs</Table.HeaderCell>
            </Table.Header>
            <Table.Body>
            {stats.map(stat => (  
                <Table.Row key={stat.object_id}>
                    <Table.Cell>
                    {stat.package_name}
                    </Table.Cell>
                    <Table.Cell textAlign='center' verticalAlign='middle' width={4}>
                    <Label circular color='grey'>{stat.num_object}</Label>
                    </Table.Cell>
                    <Table.Cell textAlign='right' verticalAlign='middle' width={1}>
                    <Label circular color='red'>{stat.num_cve}</Label>
                    </Table.Cell>
                </Table.Row>             
            ))}
            </Table.Body>
          </Table>
        )
    }

}
export default TopVulnPackage  
TopVulnPackage.propTypes = {  
    stats: PropTypes.array.isRequired, 
}  