import React, { Component } from 'react'  
import { Segment,Card, Grid, GridColumn, Divider} from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import { CspNavBar } from '../components/breadcrumb'
import Platform from '../libs/platform'
import PropTypes from 'prop-types';  
import PodListView from './podList';

export class ClusterView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            properties: props,
            loaded: false,
            clusterId: this.props.clusterId,
        }
     }

    platform = new Platform()
    
    retrieveCluster(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/clusters/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            this.setState({cluster : result, loaded: true})
            },
            (error) => {
            console.log(error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        const { clusterId } = this.state
        this.platform.setupPlatformContext(this)
        console.log("retrieve instance...")
        this.retrieveCluster(clusterId)
    }

    renderCluster() {

        const { loaded, cluster } = this.state

        if (!loaded) return

        return (
                <Grid columns={2}>
                    <GridColumn width={10}>
                    <Card key={cluster.id}>
                        <Card.Content>
                        <Card.Header>
                        {cluster.meta.name}
                        </Card.Header>
                        <Card.Meta>
                        {cluster.meta.provider}
                        </Card.Meta>
                        </Card.Content>
                    </Card>
                    <PodListView clusterId={cluster.id} />
                    </GridColumn>
                    <GridColumn width={6}>
                    </GridColumn>
                </Grid>
            
        )
    }

    render() {  

        const { cluster, loaded, userCtx, userCtxLoaded } = this.state

        if (userCtxLoaded && loaded) {

            console.log("cluster: %o", cluster)

            return (
                <div className='Main'>
                <MenuBar userContext={userCtx} activeItem="csp"></MenuBar>
                <Segment basic loading={!loaded} textAlign='left'>
                <CspNavBar userContext={userCtx} obj={cluster} objType="cluster"/>
                <Divider />
                    {this.renderCluster()}
                </Segment>
                </div>
            ) 
        }            
    }  
}
export default ClusterView

ClusterView.propTypes = {  
    clusterId: PropTypes.string.isRequired,
}  