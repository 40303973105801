import React, { Component } from 'react'
import {  Divider, Segment, Header, Card, Button, Icon, Label } from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import Platform from '../libs/platform'
import OrganizationNewInviteModal from '../components/organizationInviteModal'
import CspNavBar from '../components/breadcrumb';

export class OrganizationSettingsView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            organizations: {},
            invitations: {},
            idToIndex: {},
            orgsloaded: false,
            invitationsLoaded: false,
            forceExecLoading: false,
        }
     }

    platform = new Platform()

    retrieveOrganizationUsers = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch("/api/v1/organizations/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({organizations : result.grant_items, orgsloaded: true})
                console.log('received orgs: %o', result)
            },
            (error) => {
                console.log("cannot retrieve clusters: %o", error);
            }
        )
    }

    cancelInvitation(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({id: id}),
        };

        fetch("/api/v1/organizations/invitation/delete", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("deleted invitation: %o", result)
                this.retrieveMyInvitations()
                this.retrieveOrganizations()

            },
            (error) => {
                console.log("cannot accept invitation: %o", error);
            }
        )
    }

    retrieveInvitations() {

        console.log("retrieve invitations from state: %o", this.state)

        const { userContext } = this.state
        console.log("retrieve invitations with context: %o", userContext)

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({as_user: false}),
        };

        fetch("/api/v1/organizations/invitation/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({invitations : result.items, invitationsLoaded: true})
                console.log("received invitations: %o", result)
            },
            (error) => {
                console.log("cannot retrieve invitations: %o", error);
            }
        )
    }

    forceScheduler(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const scanURL = "/api/v1/user/scheduler/force"

        this.setState({forceExecLoading: true})

        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                this.setState({forceExecLoading: false})
                console.log(result)
            },
            (error) => {
            this.setState({forceExecLoading: false})
            console.log(error);
            }
        )
    }


    removeGrant(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const scanURL = "/api/v1/organizations/remove"

        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log("removed grant: %o", result)
            this.retrieveOrganizationUsers()
            },
            (error) => {
            console.log(error);
            }
        )
    }

    leave(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const scanURL = "/api/v1/organizations/leave"

        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("found org: %o", result)
                window.location.href='/ui/'
            },
            (error) => {
                console.log(error);
            }
        )
    }

    displayOwner(grant) {

        if (grant.is_owner == true) {
            return (
                <Label size='tiny' color='red' basic>owner</Label>
            )
        } 
    }

    componentDidUpdate() {
        console.log("component updated!")
        this.render()
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        console.log("retrieve organizations...")
        this.retrieveOrganizationUsers()
        this.retrieveInvitations()
    }

    render() {  

        const { organizations, orgsloaded, invitations, invitationsLoaded, userCtx, userCtxLoaded } = this.state

        if (!orgsloaded || !userCtxLoaded || !invitationsLoaded) {
            console.log("organizations/context still not loaded")
        } else {
            console.log("display org %o", userCtx)
                return (
                    <div className='Main'>
                    <MenuBar activeItem="orgSettings" userContext={userCtx}></MenuBar>
                    <Segment basic textAlign='left'>
                    <CspNavBar userContext={userCtx} obj={{}} objType="orgSettings"/>
                    <Divider />
                    <Header size='small'>Users for current the organization</Header>
                    <Card.Group>
                        {organizations.map(grant => (
                            <Card key={grant.id} className='break-line'>
                                <Card.Content>
                                    <Card.Header>
                                    {grant.type}
                                    </Card.Header>
                                    <Card.Meta>
                                    {grant.username}
                                    </Card.Meta>
                                    <Card.Description>
                                    {this.displayOwner(grant)}
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content textAlign='right' extra>
                                    <Card.Description>
                                        <Button 
                                        basic
                                        size='tiny'
                                        disabled={grant.is_owner}
                                        onClick={() => this.removeGrant(grant.id)}
                                        >
                                        <Icon name='delete' color='red'></Icon>
                                        Remove<faCoffee />
                                        </Button>
                                    </Card.Description>
                                </Card.Content>
                            </Card>
                        ))}
                        {invitations.map(invitation => (
                            <Card key={invitation.id} color='orange'>
                                <Card.Content>
                                    <Card.Header>
                                    Invitation
                                    </Card.Header>
                                    <Card.Meta>
                                    {invitation.type}
                                    </Card.Meta>
                                    <Card.Description>
                                    {invitation.username}
                                    </Card.Description>
                                </Card.Content>
                                <Card.Content textAlign='right' extra>
                                <Card.Description>
                                    <Button 
                                        size='tiny'
                                        basic
                                        onClick={() => this.resendInvitation(invitation.id)}>
                                    <Icon name='mail' color='green'></Icon>
                                    Re-send
                                    </Button>
                                    <Button 
                                        size='tiny'
                                        basic
                                        onClick={() => this.cancelInvitation(invitation.id)}>
                                    <Icon name='cancel' color='red'></Icon>
                                    Cancel
                                    </Button>
                                </Card.Description>
                                </Card.Content>
                            </Card>
                        ))}
                        <Card color='green'>
                        <Card.Content>
                            <Card.Header>
                                New Invitation
                            </Card.Header>
                        </Card.Content>
                        <Card.Content textAlign='right' extra>
                            <Card.Description>
                            <OrganizationNewInviteModal callerObj={this}/>
                            </Card.Description>
                        </Card.Content>
                        </Card>
                    </Card.Group>
                    <Divider />
                    <Header size='medium'>Scheduler</Header>
                    <Button
                        basic
                        loading={this.state.forceExecLoading}
                        onClick={() => this.forceScheduler(userCtx.impersonate.id)}>
                        <Icon name="play" color='blue'/> Force scheduler execution
                    </Button>
                    <Divider /> 
                    <Header size='medium'>Critical actions</Header>
                    <Button
                        basic
                        onClick={() => this.leave(userCtx.impersonate.id)}>
                        <Icon name="log out" color='red'/> Leave
                    </Button>
                    
                    </Segment>
                    </div>
                ) 
                
        }    
    }  
}
export default OrganizationSettingsView  