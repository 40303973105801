import React, { Component } from 'react'  
import { Table, Label, Modal, Button, List } from 'semantic-ui-react'
import PropTypes from 'prop-types';  
import _ from 'lodash'

export class InstancePackageVulns extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            offset: 0,
            limit: 10,
            pkg: this.props.pkg,
            vulndb: this.props.vulndb,
        }
    }

    toggleState = () => {
        console.log("change modal state")
        this.setState({
            isModalOpen: !this.state.isModalOpen,
        })
    }

    vulnColor(severity) {
        switch (severity) {
            case 5:
                return 'black'
            case 4:
                return 'red'
            case 3:
                return 'orange'
            case 2:
                return 'yellow'
            default:
                return 'grey'
        }
    }

    goToExternalLink(id) {

        if (id.startsWith("CVE")) {
            let external =  "https://nvd.nist.gov/vuln/detail/" + id
            window.open(external, "_blank")
        }
        else if (id.startsWith("GHSA")) {
            let external =  "https://github.com/advisories/" + id
            window.open(external, "_blank")
        }
        
    }

    handlePaginationClick = (event, data) => {
        let newOffset = (data.activePage - 1) * 10
        console.log("will move to offset: " + newOffset)
        this.setState({offset: newOffset})
    }
    
    renderVuln(id) {

        const {vulndb} = this.state

        let vuln = vulndb[id]

        if (vuln === undefined || vuln == null) {
            console.log("cannot find vuln %s in %o", id, vulndb)
            return
        }

        let desc = vulndb[id]
        let type = 'n/a'
        
        switch (desc.Matcher) {
            case "dpkg-matcher":
                type = 'debian package'
                break
            case "python-matcher":
                type = 'python package'
                break
            case "stock-matcher":
                type = 'Standard package'
                break
            default:
                type = desc.Matcher
        }

        return (
            <Table.Row key={desc.Id}>
                <Table.Cell width={3}>
                    <Label size='tiny' circular content={id} color={this.vulnColor(desc.Severity)} />
                </Table.Cell>
                <Table.Cell>
                    {type}
                </Table.Cell>
                <Table.Cell>
                    {desc.FixAvailable}
                </Table.Cell>
                <Table.Cell width={8}>
                    {desc.Description.slice(0,150)}...(read more)
                </Table.Cell>
                <Table.Cell width={1}>
                    <Button size='tiny' onClick={() => this.goToExternalLink(id)} icon='external' color='teal' />
                </Table.Cell>
            </Table.Row>
        )
    }

    renderPackageInfo() {
        const {pkg} = this.state

        return (
            <List bulleted>
                {pkg.Locations.map(location => (
                <List.Item key={Math.random()}>{location}</List.Item>
                ))}
            </List>
        )
    }

    renderVulnList() {

        const {pkg, isModalOpen} = this.state
        let vulnerabilities = pkg.Vulnerabilities
        vulnerabilities = _.orderBy(vulnerabilities, ['Severity'], 'desc')

        if (!isModalOpen) {
            console.log("modal closed...")
        } else {

            return (
                <div>
                <Table>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            ID
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Type
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Fix available
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Description
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Link
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                {vulnerabilities.map(vuln => (
                    this.renderVuln(vuln.Id)
                ))}
                </Table.Body>
                </Table>
            
                </div>
            )
        }
    }

    componentDidMount() {
    }

    render () {

        const { pkg } = this.state
        let numVulns = Object.keys(pkg.Vulnerabilities).length

        return (
            <Modal
                onClose={() => this.toggleState()}
                onOpen={() => this.toggleState()}
                open={this.state.isModalOpen}
                trigger={<Label circular as={"a"}>{numVulns}</Label>}
                size='large'
                className='break-line'
            >
            <Modal.Header>Locations</Modal.Header>
            <Modal.Content>
            <Modal.Description>
            {this.renderPackageInfo()}
            </Modal.Description>
            </Modal.Content>

            <Modal.Header>Vulnerabilities</Modal.Header>
            <Modal.Content scrolling>
            <Modal.Description>
            {this.renderVulnList()}
            </Modal.Description>
            </Modal.Content>

            <Modal.Actions>
            <Button color='red' onClick={() => this.toggleState()}>
            Close
            </Button>
            </Modal.Actions>
        </Modal>
        )
        
    }

}
export default InstancePackageVulns  

InstancePackageVulns.propTypes = { 
    pkg: PropTypes.object.isRequired,
    vulndb: PropTypes.object.isRequired,
}  