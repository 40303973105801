import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Card , Button, Icon, Label, Dropdown, Confirm, Grid } from 'semantic-ui-react'
import moment from 'moment'
import PropTypes from 'prop-types';  


export class InstanceCard extends Component {
    
    constructor(props) {
        super(props)
        this.state = { 
            instance: props.instance,
            scanConfirm: false,
        }
    }

    onScanCancel = () => {
        this.setState({scanConfirm: false})
    }

    scan() {
        this.setState({scanConfirm: true})
    }

    onScanSbom = (id) => {

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const scanURL = "/api/v1/instance/scansbom"

        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    delete(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const deleteURL = "/api/v1/instance/delete"

        fetch(deleteURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    goToInstance(id) {
        let instanceDetailsURL = "/ui/workloads/instances/" + id
        window.location.href = instanceDetailsURL
    }

    sbomAnalysis(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const deleteURL = "/api/v1/instance/sbom/scan"

        fetch(deleteURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            },
            (error) => {
            console.log(error);
            }
        )
    }

    displaySummary(summary) {
        if (summary == null) {
            return "No scan summary available"
        }
        let lastScan = moment.unix(summary.created_at).format('lll')

        return (
            <div>
                <div>
                   <small>{lastScan}</small>
                </div>
                <div>
                    <Label basic color='red' circular>{summary.crit_count}</Label>
                    <Label basic color='orange' circular>{summary.high_count}</Label>
                    <Label basic color='yellow' circular>{summary.med_count}</Label>
                    <Label basic color='green' circular>{summary.low_count}</Label>
                    <Label basic color='grey' circular>{summary.other_count}</Label>
                </div>
            </div>
        )
    }

    componentDidUpdate() {
        console.log('updated instance card props')
    }

    render () {
        const instance = this.props.instance
        console.log("render instance: %o", instance)
        let instanceDetailsURL = "/ui/workloads/instances/" + instance.id

        let instanceProcessing = false
        if (instance.num_processing > 0) {
            console.log("scan in progress ...")
            instanceProcessing = true
        }else {
            console.log("no scan in progress ...")
        }

        return (
            <Card key={instance.id}>
                <Card.Content>
                <Card.Header>
                    <Grid columns={2}>
                        <Grid.Column width={13} className='break-line'>
                        {instance.name}
                        </Grid.Column>
                        <Grid.Column width={3}>
                        <Icon name="server" color='red' size='large'/>
                        </Grid.Column>
                    </Grid>
                </Card.Header>
                <Card.Meta>{instance.vendor}</Card.Meta>
                </Card.Content>
                <Card.Content>
                <Card.Description>
                OS: {instance.os}<br/>
                Type: {instance.type}                            
                </Card.Description>
                </Card.Content>
                <Card.Content as={Link} to={instanceDetailsURL}>
                {this.displaySummary(instance.vuln_summary)}
                </Card.Content>

                <Card.Content textAlign='right' extra>
                <Button
                    size="tiny" 
                    basic
                    loading={instanceProcessing}
                    disabled={instanceProcessing}
                    onClick={() => this.scan(instance.id)}
                >
                <Icon name='play' color='red'></Icon>
                Scan
                </Button>
                <Dropdown icon='ellipsis horizontal' button={true} className="tiny icon" basic direction='left'>
                    <Dropdown.Menu>
                        <Dropdown.Item 
                            onClick={ () => this.goToInstance(instance.id)}
                        >
                        View last received report
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={ () => this.sbomAnalysis(instance.id)}
                        >
                        Run SBOM security analysis
                        </Dropdown.Item>
                        <Dropdown.Divider></Dropdown.Divider>
                        <Dropdown.Item
                            onClick={ () => this.delete(instance.id)}
                        >
                        Delete
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                </Card.Content>

                <Confirm
                open={this.state.scanConfirm}
                size="tiny"
                content='Your instance will be cloned with the same machine type. This clone will be analyzed with our scanning tools. The instance clone may add some extra CSP cost.'
                confirmButton="Let's do it"
                onCancel={this.onScanCancel}
                onConfirm={ () => this.onScanConfirm(instance.id)}
                />
            </Card>
        )
    }

}
export default InstanceCard  

InstanceCard.propTypes = {  
    instance: PropTypes.object.isRequired, 
}  