import React, { Component } from 'react'  
import { Button, Icon, Container, Modal, Input, Form } from 'semantic-ui-react'
import PropTypes from 'prop-types'


export class OrganizationNewModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
        }
    }

    toggleState = () => {
        console.log("change modal state")
        this.setState({isModalOpen: !this.state.isModalOpen})
    }
   

    saveOrgName = (event, data) => {
        this.setState({orgName: data.value})
    }

    addNewOrg (){
        console.log("create new org %s", this.state.orgName)
        this.toggleState()

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"name": this.state.orgName})
        };

        const scanURL = "/api/v1/organizations/create"

        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                console.log("added org: %o", result)
                this.props.callerObj.newOrgAdded()
            },
            (error) => {
            console.log(error);
            }
        )
    }

    render () {

        return (
            <Container>
                <Modal
                    onClose={() => this.toggleState()}
                    onOpen={() => this.toggleState()}
                    open={this.state.isModalOpen}
                    trigger={<Button size='small' basic><Icon color='green' name="plus" />Add</Button>}
                    size="tiny"
                >
                <Modal.Header>Create a new organization</Modal.Header>

                <Modal.Content>
                <Modal.Description>
                <Form>
                    <Form.Field>
                        <Input
                            label={{ basic: true, content: 'Organization Name' }}
                            labelPosition='left'
                            placeholder='Enter your organization name...'
                            onChange={this.saveOrgName}
                            fluid
                        />
                    </Form.Field>
                </Form>                    
                </Modal.Description>
                </Modal.Content>

                <Modal.Actions>
                <Button color='red' onClick={() => this.toggleState()}>
                Cancel
                </Button>
                <Button
                    color="green" onClick={() => this.addNewOrg()}>
                Add
                </Button>
                </Modal.Actions>
            </Modal>
            </Container>
        )
        
    }
}

export default OrganizationNewModal

OrganizationNewModal.propTypes = {  
    callerObj: PropTypes.object.isRequired
}  