import React, { Component } from 'react'
import { Form , Button ,  Segment, Card, Container } from 'semantic-ui-react'  
import { MenuBar } from '../components/menubar.js'  
import { Platform } from '../libs/platform.js'



export class UserView extends Component {

    constructor(props) {
        super(props)
        this.state = {
           loaded: false,
        }
     }

    platform = new Platform()
    

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
    }

    render() {  
        
        const { userCtx, userCtxLoaded } = this.state
        console.log("display ctx: %o", this.state)

        if (!userCtxLoaded) {
            console.log("still not loaded")
        } else {
            return (  
                <div className='App'>
                    <MenuBar userContext={userCtx}></MenuBar>
                    <div className='Main-body'>
                        <Container>
                                <Card centered>
                                    <Card.Description>
                                        <Segment basic>
                                    <Form>
                                        <Form.Field>
                                            <label>Username</label>
                                            <input placeholder='Username' defaultValue={userCtx.user.username} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>First Name</label>
                                            <input placeholder='First Name' defaultValue={userCtx.user.firstName} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Last Name</label>
                                            <input placeholder='Last Name' defaultValue={userCtx.user.lastName} />
                                        </Form.Field>
                                        <Form.Field>
                                            <label>Email</label>
                                            <input placeholder='Email' defaultValue={userCtx.user.email} />
                                        </Form.Field>
                                    <Button type='submit' color='teal'>Save</Button>
                                    </Form>
                                    </Segment>
                                    </Card.Description>
                                </Card>
                        </Container>
                    </div>
                </div>

            )  
        }  
    }
}
export default UserView  
