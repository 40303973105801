import React, { Component } from 'react'  
import { Button, Icon, Container, Modal, Dropdown, Input, Form, Message, Segment } from 'semantic-ui-react'

export class AccountModalNew extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isModalOpen: false,
            isRedirect: false,
        }
    }

    toggleState = () => {
        console.log("change modal state")
        this.setState({
            isModalOpen: !this.state.isModalOpen,
            isRedirect: false,
            cspName: null,
            accountName: null,
            accountKey: null,
            accountSecret: null,
        })
    }

    setCSP(name) {
        console.log("set csp name: " + name)
        this.setState({cspName: name})

        if (name == "OVH" || name == "AZURE" || name == "GITHUB" || name == "JIRA" || name == "GITLAB") {
            this.setState({isRedirect: true})
        } else {
            this.setState({isRedirect: false})
        }
    }

    jumpToCsp = (vendor) => {

        if (vendor == "AZURE" || vendor == "JIRA" || vendor == "GITHUB" || vendor == "GITLAB") {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({"provider": vendor})
            };

            const scanURL = "/api/v1/oauth/register"

            fetch(scanURL, requestOptions)
            .then(res => res.json())
            .then(
                (result) => {
                    window.open(result.redirect_url, '_blank').focus();
                },
                (error) => {
                console.log(error);
                }
            )
        } else if (vendor == "OVH") {
            window.open('/api/v1/ovh', '_blank').focus();
        }

        this.toggleState()

    }

    saveCSPName = (event, data) => {
        this.setState({accountName: data.value})
    }

    saveCSPKey = (event, data) => {
        this.setState({accountKey: data.value})
    }

    saveCSPSecret = (event, data) => {
        this.setState({accountSecret: data.value})
    }

    addNewCSP (){
        console.log("add new csp %s with key %s", this.state.accountName, this.state.accountKey)

        if (this.state.isRedirect) {
            this.jumpToCsp(this.state.cspName)
            return
        }

        if (this.state.accountName.length > 0 &&
            this.state.accountKey.length > 0 &&
            this.state.accountSecret.length > 0) {
                
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({"vendor": this.state.cspName, 
                        "name": this.state.accountName,
                        "access_key": this.state.accountKey,
                        "access_secret": this.state.accountSecret})
                };
    
                const scanURL = "/api/v1/credentials/create"
    
                fetch(scanURL, requestOptions)
                .then(res => res.json())
                .then(
                    (result) => {
                        console.log("created secret: %o", result)
                    },
                    (error) => {
                    console.log(error);
                    }
                )


            }

        this.toggleState()
    }

    showCredsForm() {

        if (this.state.isRedirect) {
            return (
                <Segment basic>
                <Message color='green'>You will be redirected to your CSP authentication and authorization portal.</Message>
                </Segment>
            )
        } 
        else if (this.state.cspName == null) {
            return
        }
        else {
            return (
               <Segment basic>
                <Form>
                    <Form.Field>
                        <Input
                            label={{ basic: true, content: 'Account name' }}
                            labelPosition='left'
                            placeholder='Enter your accountname...'
                            onChange={this.saveCSPName}
                            fluid
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            label={{ basic: true, content: 'API Key' }}
                            labelPosition='left'
                            placeholder='Enter your API key...'
                            onChange={this.saveCSPKey}
                            fluid
                        />
                    </Form.Field>
                    <Form.Field>
                        <Input
                            label={{ basic: true, content: 'API Secret' }}
                            labelPosition='left'
                            placeholder='Enter your API secret...'
                            onChange={this.saveCSPSecret}
                            type="password"
                            fluid
                        />
                    </Form.Field>
                </Form>                    
                </Segment>
            )
        }
    }

    render () {

        const { cspName } = this.state

        let currentCspName = 'Choose your provider...'
        if (cspName != undefined) {
            currentCspName = cspName
        }

        return (
            <Container fluid>
                <Modal
                    onClose={() => this.toggleState()}
                    onOpen={() => this.toggleState()}
                    open={this.state.isModalOpen}
                    trigger={<Button  basic size='tiny'><Icon color='green' name="plus circle" />Add</Button>}
                    size="tiny"
                >
                <Modal.Header>Link to a new Cloud Service Provider</Modal.Header>
                <Modal.Content>
                <Modal.Description>
                    <Dropdown text={currentCspName}>
                        <Dropdown.Menu>
                            <Dropdown.Header>European CSP</Dropdown.Header>
                            <Dropdown.Item onClick={() => this.setCSP("OVH")}>OVH Cloud</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setCSP("SCALEWAY")}>Scaleway</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setCSP("EXOSCALE")}>Exoscale</Dropdown.Item>
                            <Dropdown.Divider></Dropdown.Divider>
                            <Dropdown.Header>United States CSP</Dropdown.Header>
                            <Dropdown.Item onClick={() => this.setCSP("AWS")}>AWS</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setCSP("AZURE")}>Microsoft Azure</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setCSP("GOOGLE")}>Google Cloud</Dropdown.Item>
                            <Dropdown.Header>Container Images Repositories</Dropdown.Header>
                            <Dropdown.Item onClick={() => this.setCSP("DOCKER")}>Docker Registry</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setCSP("DOCKER_HUB")}>Docker Hub</Dropdown.Item>
                            <Dropdown.Header>Code Repositories</Dropdown.Header>
                            <Dropdown.Item onClick={() => this.setCSP("GITHUB")}>Github</Dropdown.Item>
                            <Dropdown.Item onClick={() => this.setCSP("GITLAB")}>Gitlab</Dropdown.Item>
                            <Dropdown.Header>Project Management</Dropdown.Header>
                            <Dropdown.Item onClick={() => this.setCSP("JIRA")}>JIRA</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    {this.showCredsForm()}

                </Modal.Description>
                </Modal.Content>


                <Modal.Actions>
                <Button color='red' onClick={() => this.toggleState()}>
                Cancel
                </Button>
                <Button
                    color="green" onClick={() => this.addNewCSP()}>
                Add
                </Button>
                </Modal.Actions>
            </Modal>
            </Container>
        )
        
    }
}

export default AccountModalNew