import React, { Component } from 'react'
import { Card , Container, Header } from 'semantic-ui-react'
import PodCard from '../components/podCard'
import Platform from '../libs/platform'
import PropTypes from 'prop-types';  
import _ from 'lodash'

export class PodListView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            clusterId: this.props.clusterId,
            instances: {},
            loaded: false,
        }
     }

    platform = new Platform()

    retrievePods() {

        const { clusterId } = this.state

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"cluster_id": clusterId})
        };

        fetch("/api/v1/pod/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {

                result.list = _.orderBy(result.list, (item) => {
                    if (item.num_crit > 0 ) return 5
                    if (item.num_high > 0) return 4
                    if (item.num_med > 0) return 3
                    if (item.num_low > 0) return 2
                    if (item.num_others > 0) return 1;
                    return 0
                }, 'desc')


                this.setState({pods : result, loaded: true})
                console.log("got pods...")
            },
            (error) => {
            console.log(error);
            }
        )
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
        console.log("retrieve pods...")
        this.retrievePods()
    }

    render() {  
        const { pods, loaded, userCtxLoaded } = this.state
        console.log("found pods: %o", pods)
        if (!loaded || !userCtxLoaded) {
            console.log("still not loaded")
        } else {

            return (
                <Container fluid>
                    <Header>Workloads</Header>
                    <Card.Group>
                        {pods.list.map(pod => (
                            <PodCard key={pod.id} pod={pod}/>
                        ))}
                    </Card.Group>
                </Container>
            ) 
        }    
    }  
}
export default PodListView

PodListView.propTypes = {  
    clusterId: PropTypes.string.isRequired, 
}  