import React, { Component } from 'react'
import {   Divider, Segment, Container } from 'semantic-ui-react'
import { MenuBar } from '../components/menubar'
import { OrganizationTable } from '../components/organizationTable'
import Platform from '../libs/platform'
import CspNavBar from '../components/breadcrumb';

export class OrganizationListView extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            organizations: {},
            invitations: {},
            idToIndex: {},
            orgsLoaded: false,
            invitationsLoaded: false,

        }
     }

    platform = new Platform()

    componentDidUpdate() {
        console.log("component updated!")
    }

    // This view require to be authenticated 
    componentDidMount() {
        this.platform.setupPlatformContext(this)
    }

    render() {  

        const { userCtx, userCtxLoaded } = this.state

        if (!userCtxLoaded) {
            console.log("loading...") 
        }
        else {
            return (
                <div className='Main'>
                <MenuBar activeItem="org" userContext={userCtx}></MenuBar>
                <Segment basic textAlign='left'>
                <CspNavBar userContext={userCtx} obj={{}} objType=""/>
                <Divider /> 
                <Container fluid>
                <OrganizationTable />
                </Container>
                </Segment>
                </div>
            ) 
        }
            
         
    }  
}
export default OrganizationListView  