import React, { Component } from 'react'
import { Card , Container, Segment } from 'semantic-ui-react'
import InstanceCard from './instanceCard'
import Platform from '../libs/platform'
import { w3cwebsocket as W3CWebSocket } from "websocket";
import PropTypes from 'prop-types';  
import _ from 'lodash'

export class InstanceListView extends Component {
    constructor(props) {
        super(props)
        this.state = {
            cspId: this.props.cspId,
            instances: {},
            loaded: false,
        }
     }

    platform = new Platform()

    subscribeForInstances() {
        const info = window.location
        const websocketURL = 'wss://' + info.host + '/ui/notifications'
        const client = new W3CWebSocket(websocketURL);
        client.onopen = () => {
            console.log('WebSocket Client Connected');
        };
        client.onmessage = (message) => {
            let event = JSON.parse(message.data);
            console.log("event: %o", event)

            if (event.Ping) {
                console.log("ws ping")
                return
            }

            if (event.Reason && event.Reason.length > 0) {
                console.log("error from websocket: " + event.Reason)
                return
            }

            const instanceIndex = this.state.idToIndex[event.objectId]

            if (event.objectType != "OBJECT_TYPE_INSTANCE") {
                return
            }

            switch (event.event) {
                case "OBJECT_ACTION_UPDATE":
                    console.log("will refresh instance at index %d", instanceIndex)
                    this.retrieveInstance(event.objectId, instanceIndex)
                    break
                default:
                    console.log("unhandled event: %o", event)
            }
        };
    }

    retrieveInstance(id, index) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        fetch("/api/v1/instance/read", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                let newInstances = this.state.instances
                newInstances.list[index] = result
                this.setState(newInstances)
                console.log("state updated with new instance props: %o", result)
            },
            (error) => {
            console.log(error);
            }
        )
    }


    retrieveInstances() {

        const { cspId } = this.state

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"cred_id": cspId})
        };

        fetch("/api/v1/instance/list", requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
                result.list = _.orderBy(result.list, (item) => {
                    if (item.vuln_summary != null) return item.vuln_summary.created_at;
                    else return 0
                }, 'desc')

                let idToIndex = {}
                result.list.forEach((instance, index) => {
                    idToIndex[instance.id] = index
                });
                this.setState({instances : result, loaded: true, idToIndex: idToIndex})
                console.log("got instances...")
            },
            (error) => {
            console.log(error);
            }
        )
    }

    scanInstance(id) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({"id": id})
        };

        const scanURL = "/api/v1/instances/scan"

        fetch(scanURL, requestOptions)
        .then(res => res.json())
        .then(
            (result) => {
            console.log(result)
            },
            (error) => {
            console.log(error);
            }
        )
    }


    handlePaginationClick = (event, data) => {
        const { numCard } = this.state
        let newOffset = (data.activePage - 1) * numCard
        console.log("will move to offset: " + newOffset)
        this.setState({offset: newOffset})
    }
    

    // This view require to be authenticated 
    componentDidMount() {
        //this.platform.setupPlatformContext(this)
        console.log("retrieve instances...")
        this.retrieveInstances()
        this.subscribeForInstances()
    }

    render() {  
        const { instances, loaded } = this.state
        console.log("found instances: %o", instances)
        if (!loaded ) {
            console.log("still not loaded")
        } else {
            return (              
                <Container fluid>
                    <Segment basic textAlign='left'>
                    <Card.Group > 
                        {instances.list.map(instance => (
                            <InstanceCard key={instance.id} instance={instance}/>
                        ))}
                    </Card.Group>
                    </Segment>
                </Container>
            ) 
        }    
    }  
}
export default InstanceListView

InstanceListView.propTypes = {  
    cspId: PropTypes.string.isRequired, 
}  